import React from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import { Header } from '$gcomponents/widgets';
import { userActions } from '$gbusiness/redux/user';

import { IonPageWrapper } from './styles';
import CartList from '$components/cartList';
import CartItemModel from '$fbusiness/models/cartItem';
import IonContentWrapper from '$fcomponents/ionContentWrapper';

interface CartScreenProps {
  onDehydrate;
  cart: Array<CartItemModel>;
}

const CartScreen: React.FC<CartScreenProps> = ({ cart }) => {
  return (
    <IonPageWrapper>
      <Header title="SCREEN.CART.TITLE" />
      <IonContentWrapper>
        <CartList items={cart} />
      </IonContentWrapper>
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  cart: state.cart.cart,
});

const mapDispatchToProps = {
  onDehydrate: userActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CartScreen));
