import React from 'react';
import { IonButtons, IonFooter, IonButton, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/react';
import intl, { Text } from '$gintl';
import { CancelButton, ModalWrapper } from './styles';

interface ModalProps {
  show: boolean;
  title?: string;
  titleText?: string;
  className?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  useCustom?: boolean;
  minWidth?: string;
  width?: string;
  hasClose?: boolean;
  minHeight?: string;
  height?: string;
  borderRadius?: string;
  backdropDismiss?: boolean;
  onClose: () => void;
  onPressClose?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  show,
  title = '',
  titleText,
  children,
  className,
  footer,
  useCustom,
  hasClose = false,
  minWidth,
  width,
  minHeight,
  height,
  backdropDismiss = true,
  borderRadius = '10px',
  onClose,
  onPressClose,
}) => {
  return (
    <ModalWrapper
      minWidth={minWidth}
      width={width}
      minHeight={minHeight}
      height={height}
      borderRadius={borderRadius}
      isOpen={show}
      cssClass={className}
      canDismiss={true}
      backdropDismiss={backdropDismiss}
      onDidDismiss={onClose}>
      <>
        {hasClose && <CancelButton className="pointer cancel-button" onClick={onPressClose || onClose} />}
        {(title || titleText) && (
          <IonHeader>
            <IonToolbar>
              <IonTitle>{titleText || intl(title)}</IonTitle>
              <IonButtons slot="end">
                <IonButton className="no-print" onClick={onPressClose || onClose}>
                  <Text k="BUTTON.CLOSE" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        {useCustom && <>{children}</>}
        {!useCustom && <IonContent fullscreen>{children}</IonContent>}
        {footer && (
          <IonFooter>
            <IonToolbar>{footer}</IonToolbar>
          </IonFooter>
        )}
      </>
    </ModalWrapper>
  );
};

export default Modal;
