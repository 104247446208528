import DailyReportItem, { deriveRawToDailyReportItem } from './dailyReportItem';

export default interface DailyReportModel {
  id: number;
  userId: number;
  author: string;
  reportDate: string | null;
  regionId: number;
  regionName?: string;
  status?: string;
  items: Array<DailyReportItem>;
  settings?: any;
}

export const deriveRawToDailyReport = (raw) => {
  return {
    id: raw.id,
    userId: raw.userId,
    author: raw.author,
    reportDate: raw.reportDate,
    regionId: raw.region?.id || 0,
    ...(raw.region && { regionName: raw.region?.name || '' }),
    status: raw.status,
    items: raw.items.map(deriveRawToDailyReportItem),
    settings: {
      ...(raw.settings || {}),
    },
  };
};

export const initialDailyReport: DailyReportModel = {
  id: 0,
  userId: 0,
  author: '',
  regionId: 0,
  regionName: '',
  reportDate: null,
  items: [],
};
