import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$fcomponents/hoc';

import { IonPageWrapper } from './styles';
import MainHeader from '$components/mainHeader';
import CartModel from '$fbusiness/models/cart';
import StoreModel from '$fbusiness/models/store';
import { CHECKOUT_TABLE, CHECKOUT_TABLE_CONFIG } from './table';
import { TableView2 } from '$gcomponents/widgets';
import { Redirect } from 'react-router';
import { ROUTES } from '$business/enums';
import intl from '$gintl';
import CheckoutFooter from './checkoutFooter';
import { orderActions } from '$fbusiness/redux/order';
import { cartToPoRequest, cartToRefundRequest, separatePoRefund } from './utils';
import CheckoutNote from './checkoutNote';
import { getTaxRate } from '$fbusiness/helpers/util';
import { cartActions } from '$fbusiness/redux/cart';
import { checkCartStock, syncCart } from '$fbusiness/redux/cart/utils';
import { dialog } from '$gcomponents/reusables';

interface CheckoutScreenProps {
  history: any;
  store: StoreModel;
  factory;
  cart: CartModel;
  currentState;
  submitPo;
  submitRefund;
  refreshCart;
  refund;
  order;
}

const CheckoutScreen: React.FC<CheckoutScreenProps> = ({
  store,
  factory,
  cart,
  history,
  refreshCart,
  currentState,
  submitPo,
  submitRefund,
  refund,
  order,
}) => {
  const [note, setNote] = useState('');

  useEffect(() => {
    if (!refund) return;
    const [poItems] = separatePoRefund(cart);
    if (poItems.length)
      submitPo(
        0,
        cartToPoRequest({
          price: cart.pricing.po,
          items: poItems,
          note,
          store,
          user,
          taxRate: getTaxRate(store, factory),
          refundId: refund?.id || 0,
        }),
      );
    else history.push(`${ROUTES.PATH.THANKYOU}/0/${refund.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refund]);

  useEffect(() => {
    if (!order) return;
    history.push(`${ROUTES.PATH.THANKYOU}/${order?.id || 0}/${refund?.id || 0}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  if (!store) return <Redirect to={ROUTES.DEFAULT_APP_PATH} />;
  const { user } = currentState;

  const onClickSubmit = async () => {
    const syncedCart = await syncCart(cart, store);
    if (checkCartStock(syncedCart)) {
      await onSubmitPo();
      return;
    }
    refreshCart(syncedCart);
    dialog.alert({
      text: intl('MESSAGE.CHECKOUT_STOCK'),
    });
  };

  const onSubmitPo = async () => {
    const [poItems, refundItems] = separatePoRefund(cart);

    if (refundItems.length) {
      await submitRefund(
        0,
        cartToRefundRequest({
          price: cart.pricing.refund,
          items: refundItems,
          note,
          user,
          store,
          taxRate: getTaxRate(store, factory),
        }),
      );
    } else if (poItems.length) {
      await submitPo(
        0,
        cartToPoRequest({
          price: cart.pricing.po,
          items: poItems,
          note,
          store,
          user,
          taxRate: getTaxRate(store, factory),
        }),
      );
    }
  };

  const data = [...(cart.products || [])];
  data.sort((a, b) => a.settings?.isRefund - b.settings?.isRefund);

  return (
    <IonPageWrapper>
      <MainHeader
        titleText={intl('SCREEN.CHECKOUT.TITLE', { qty: 135 })}
        currentState={currentState}
        hasBack
        showTitle
      />
      <CheckoutNote note={note} onChange={(note) => setNote(note)} />
      <IonContent>
        <TableView2
          className="table2"
          data={data}
          tableConfig={CHECKOUT_TABLE_CONFIG}
          TABLE={CHECKOUT_TABLE(currentState.isWholesale)}
        />
      </IonContent>
      <CheckoutFooter cart={cart} store={store} user={user} onSubmitPo={onClickSubmit} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  resetOnRoute: true,
  refund: state.order.refund,
  order: state.order.order,
  factory: state.factory.factory,
  store: state.store.store,
  cart: state.localStorage.cart,
});

const mapDispatchToProps = {
  refreshCart: cartActions.refreshCart,
  submitPo: orderActions.saveOrder,
  submitRefund: orderActions.requestRefund,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CheckoutScreen));
