import React, { useState } from 'react';
import intl from '$intl';
import { IonHeaderWrapper } from './styles';
import { Flex } from '$gstyles/wrapper';
import { Add } from '@mui/icons-material';
import { Button } from '$gcomponents/primitives';
import { sleep } from '$gbusiness/helpers/util';
import { getEl } from '$gbusiness/helpers/input';

interface CheckoutNoteProps {
  note: string;
  onChange: Function;
}

const CheckoutNote: React.FC<CheckoutNoteProps> = ({ note, onChange }) => {
  const [value, setValue] = useState(note);
  const [isEditing, setIsEditing] = useState(false);

  const saveNote = () => {
    onChange(value);
    setIsEditing(false);
  };
  const cancelNote = () => {
    setValue(note);
    setIsEditing(false);
  };
  const toEditMode = async () => {
    setIsEditing(true);
    await sleep(200);
    getEl('.note-input').focus();
  };

  const addModeRender = !value && !isEditing && (
    <>
      <Add className="add-button" onClick={toEditMode} />
      {intl('SCREEN.CHECKOUT.BUTTON_NOTE')}
    </>
  );

  const editModeRender = isEditing && (
    <>
      <input type="text" className="note-input" value={value} onChange={(e) => setValue(e.target.value)} />
      <Button color="secondary" className="save-button" onClick={saveNote}>
        {intl('BUTTON.SAVE')}
      </Button>
      <Button variant="outlined" color="secondary" className="cancel-button" onClick={cancelNote}>
        {intl('BUTTON.CANCEL')}
      </Button>
    </>
  );

  const displayModeRender = !isEditing && note && (
    <>
      <div className="display-section">{note}</div>
      <Button variant="outlined" color="secondary" className="edit-button" onClick={toEditMode}>
        {intl('BUTTON.EDIT')}
      </Button>
    </>
  );

  return (
    <IonHeaderWrapper>
      <Flex className="wrapper">
        {addModeRender} {editModeRender} {displayModeRender}
      </Flex>
    </IonHeaderWrapper>
  );
};

export default CheckoutNote;
