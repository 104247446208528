export interface SimpleModel {
  id: number;
  name?: string;
}

export const initialSimple = {
  id: 0,
  name: '',
};

export const deriveRawToSimple = (raw) => ({
  id: raw.id,
  name: raw.name,
});
