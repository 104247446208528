import React, { useState } from 'react';
import { connect } from 'react-redux';
import { screen } from '$fcomponents/hoc';
import { Row } from '$gstyles';

import { IonPageWrapper } from './styles';
import CurrentStateModel from '$fbusiness/models/currentState';
import FactoryModel from '$fbusiness/models/factory';
import MainHeader from '$components/mainHeader';
import intl from '$gintl';
import { PageWrapper } from '$gstyles/wrapper';
import MainButton from './mainButton';
import { IonContent } from '@ionic/react';
import FPATH from '$fbusiness/enums/paths';
import { initActions } from '$business/redux/init';
import PATH from '$business/enums/paths';

interface HomeScreenProps {
  factory: FactoryModel;
  currentState: CurrentStateModel;
  getMyStore: Function;
  storeId: number;
  history;
}

const HomeScreen: React.FC<HomeScreenProps> = ({ history, storeId, getMyStore, currentState }) => {
  const { user } = currentState;
  const [showModal, setShowModal] = useState(false);

  const nav = (route) => {
    history.push(route);
  };

  const handlePoClick = async () => {
    await getMyStore(storeId);
    await setShowModal(true);

    // if (!store || !store.id) {
    //   await setShowModal(true);
    //   return;
    // }
    // await getMyStore(storeId);
    // history.push(PATH.PO);
  };

  const onSelectStore = async () => {
    history.push(PATH.PO);
  };

  return (
    <IonPageWrapper>
      <MainHeader
        showModal={showModal}
        onSetModal={setShowModal}
        onSelectStore={onSelectStore}
        title="SCREEN.HOME.TITLE"
        currentState={currentState}
      />
      <IonContent>
        <PageWrapper className="page-wrapper">
          <div className="subtitle">{intl('SCREEN.HOME.SUBTITLE', { name: user?.firstName || 'there' })}</div>
          <div className="buttons-container">
            <Row>
              <MainButton onClick={handlePoClick} text={intl('SCREEN.HOME.BUTTON.CREATE_PO')} />
              <MainButton onClick={() => nav(FPATH.HISTORY)} text={intl('SCREEN.HOME.BUTTON.HISTORY')} />
              <MainButton onClick={() => nav(FPATH.DAILY_REPORT)} text={intl('SCREEN.HOME.BUTTON.REPORT')} />
              <MainButton onClick={() => nav(FPATH.ACCOUNT)} text={intl('SCREEN.HOME.BUTTON.MANAGE')} />
            </Row>
          </div>
        </PageWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  storeId: state.localStorage.storeId,
});

const mapDispatchToProps = {
  getMyStore: initActions.fetchMyStore,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(HomeScreen));
