import CartModel from '$fbusiness/models/cart';
import { deriveCartToOrderItem } from '$fbusiness/models/orderInvoiceItem';
import { today } from '$gbusiness/helpers/date';

export const separatePoRefund = (cart: CartModel) => {
  const refundItems = cart.products.filter((p) => p.settings?.isRefund);
  const poItems = cart.products.filter((p) => !p.settings?.isRefund);
  return [poItems, refundItems];
};

export const cartToPoRequest = ({
  price,
  items,
  note,
  deliveryDate = today(),
  user,
  store,
  taxRate,
  refundId = 0,
}) => {
  const commRate = getCommissionRate(user, price.discountRate > 0);

  const { id: storeId, formatted: deliveryAddress } = store;
  return {
    items: items.map(deriveCartToOrderItem),
    paymentTypeId: 0,
    shippingId: 0,
    storeId,
    refundId,
    ...price,
    commRate,
    taxRate,
    deliveryAddress,
    deliveryDate,
    note,
    textConfirmation: store?.settings?.textConfirmation || false,
    emailConfirmation: store?.settings?.emailConfirmation || false,
  };
};

// discountRate: number;
// taxRate: number;

// subtotal: number;
// itemDiscount: number;
// itemTaxable: number;
// itemTax: number;
// itemTotal: number;

// delivery: number;
// otherDiscount: number;
// totalDiscount: number;
// taxable: number;
// otherTax: number;

// tax: number;
// qty: number;
// tip: number;
// total: number;

export const cartToRefundRequest = ({ price, user, items, note, store, taxRate }) => {
  const { id: storeId } = store;
  const commRate = getCommissionRate(user, price.discountRate > 0);
  return {
    items: items.map(deriveCartToOrderItem),
    ...price,
    paymentTypeId: 0,
    shippingId: 0,
    storeId,
    commRate,
    taxRate,
    note,
  };
};

export const getCommissionRate = (user, hasDiscount) => {
  const hasCommission = !!user?.other?.commission;
  if (!hasCommission) return 0;
  return hasDiscount ? user?.other?.commission.discountRate || 0 : user?.other?.commission.rate || 0;
};
