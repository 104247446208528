import React from 'react';
import intl from '$intl';
import { Flex } from '$gstyles/wrapper';
import { Autocomplete } from '$gcomponents/reusables';
import { Div, SPACE } from '$gstyles';
import { getPublicFactoryId } from '$gbusiness/helpers/util';
import { configs } from '$configs';
import { IonHeaderWrapper } from './styles';
import { Button } from '$gcomponents/primitives';
import { barcodeOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { getEl } from '$gbusiness/helpers/input';
import { deriveRawToItem } from '$fbusiness/models/item';

interface PoSearchBarProps {
  scanMode: boolean;
  onSelectItem: Function;
  onClickScan: Function;
  onScanFetch: Function;
}

export const AUTO_COMPLETE = (onClickItem) => ({
  method: 'POST',
  minChar: 2,
  renderSuggestion: (e) => (
    <Flex justifyContent="space-between">
      {e.sku == null ? (
        <>
          <Div marginLeft={SPACE.SMALL}>{e.name}</Div>
        </>
      ) : (
        <>
          <div>{`${e.name} (${e.sku}) ${e.barcode && ` - ${e.barcode}`}`}</div>
          {e.outOfStock ? (
            <div>{intl('COMMON.OUT_OF_STOCK')}</div>
          ) : (
            <Button
              className="primary filled"
              styles={{ backgroundColor: '#000' }}
              size="small"
              color="secondary"
              onClick={() => onClickItem(e)}>
              {intl('SCREEN.PO.BUTTON.ADD')}
            </Button>
          )}
        </>
      )}
    </Flex>
  ),
  getLabel: (obj) => obj.name,
  onEnter: () => {
    // FOR SCAN. ADD ITEM
  },
  deriveRaw: (response) => {
    if (!response) return [];
    if (response.items) return [...response.categories, ...response.items.map(deriveRawToItem)];
    return [];
  },
});

const PoSearchBar: React.FC<PoSearchBarProps> = ({ scanMode, onScanFetch, onSelectItem, onClickScan }) => {
  const handleClickScan = () => {
    focusOnSearchBar();
    onClickScan();
  };
  const focusOnSearchBar = () => {
    const el = getEl('.searchInput .react-autosuggest__input');
    if (el) el.focus();
  };
  return (
    <IonHeaderWrapper>
      <Flex justifyContent="space-between">
        <Flex className="searchInput" flex={8}>
          <Autocomplete
            hideSearchIcon
            endpoint={scanMode ? configs.api.item.scan : configs.api.item.search}
            AC={{
              ...AUTO_COMPLETE(onSelectItem),
              ...(scanMode
                ? {
                    onResponse: onScanFetch,
                    clearOnFetch: true,
                    method: 'GET',
                  }
                : {
                    clearOnSelect: true,
                  }),
              extraParam: { factoryId: getPublicFactoryId(), itemOnly: true },
            }}
            height="32px"
            width="calc(100% - 80px)"
          />
          <Button onClick={focusOnSearchBar}>{intl('SCREEN.PO.BUTTON.SEARCH')}</Button>
        </Flex>
        <Div className="scan-barcode" flex={5}>
          <Button onClick={handleClickScan} className="barcode-button">
            <IonIcon icon={barcodeOutline} size="large" />
            {intl('SCREEN.PO.BUTTON.SCAN')}
          </Button>
        </Div>
      </Flex>
    </IonHeaderWrapper>
  );
};

export default PoSearchBar;
