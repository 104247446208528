import GPATH from '$gbusiness/enums/paths';
import RouteModel from '$gbusiness/models/route';
import PATH from './paths';

import {
  Person,
  ArrowDropDownCircle,
  History,
  Receipt,
  ShoppingCart,
  Settings,
  AccountBox,
  StoreMallDirectory,
  AssessmentOutlined,
  Group,
  FolderShared,
  Today,
  AttachMoney,
} from '@mui/icons-material';
import AccountScreen from '../../screens/accountScreen';
import CartsScreen from '../../screens/accountScreen/cartsScreen';
import HistoryScreen from '../../screens/accountScreen/historyScreen';
import InvoicesScreen from '../../screens/accountScreen/invoicesScreen';
import ReceivingScreen from '../../screens/accountScreen/invoicesScreen/receivingScreen';
import ProfileScreen from '../../screens/accountScreen/profileScreen';
import SettingsScreen from '../../screens/accountScreen/settingsScreen';
import UserScreen from '../../screens/users/userScreen';
import UsersScreen from '../../screens/users/usersScreen';
import TosScreen from '../../screens/tos';
import MyFilesScreen from '../../screens/accountScreen/myFilesScreen';
import MyVendorsScreen from '../../screens/accountScreen/myVendorsScreen';
import DailyReportScreen from '../../screens/dailyReportScreens/dailyReportScreen';
import DailyReportsScreen from '../../screens/dailyReportScreens/dailyReportsScreen';
import creditsScreen from '$fscreens/accountScreen/creditsScreen';
import ArReportScreen from '../../screens/accountScreen/arReportScreen';

const { USERS } = GPATH;

export { PATH };

export const ACCOUNT_ROUTE: RouteModel = {
  title: 'MENU.MY_ACCOUNT',
  icon: Person,
  path: PATH.ACCOUNT,
  component: AccountScreen,
};

export const SETTINGS_ROUTE: RouteModel = {
  title: 'MENU.SETTINGS',
  subtitle: 'SCREEN.ACCOUNT.SETTINGS',
  icon: Settings,
  path: PATH.SETTINGS,
  component: SettingsScreen,
  accessLevel: 2,
};

export const PROFILE_ROUTE: RouteModel = {
  title: 'MENU.PROFILE',
  subtitle: 'SCREEN.ACCOUNT.PROFILE',
  path: PATH.PROFILE,
  icon: AccountBox,
  component: ProfileScreen,
};

export const HISTORY_ROUTE: RouteModel = {
  title: 'MENU.HISTORY',
  subtitle: 'SCREEN.ACCOUNT.HISTORY',
  path: PATH.HISTORY,
  icon: History,
  component: HistoryScreen,
};

export const AR_REPORT_ROUTE: RouteModel = {
  title: 'MENU.REPORT_AR',
  subtitle: 'SCREEN.ACCOUNT.HISTORY',
  path: PATH.REPORT_AR,
  icon: AssessmentOutlined,
  component: ArReportScreen,
};

export const INVOICES_ROUTE: RouteModel = {
  title: 'MENU.INVOICES',
  subtitle: 'SCREEN.ACCOUNT.INVOICES',
  path: PATH.INVOICES,
  icon: Receipt,
  feature: 'invoice',
  component: InvoicesScreen,
};

export const RECEIVING_ROUTE: RouteModel = {
  title: 'MENU.RECEIVINGS',
  subtitle: 'SCREEN.ACCOUNT.RECEIVINGS',
  path: PATH.RECEIVING,
  icon: ArrowDropDownCircle,
  component: ReceivingScreen,
};

export const CREDITS_ROUTE: RouteModel = {
  title: 'MENU.CREDITS',
  subtitle: 'SCREEN.CREDIT.SUBTITLE',
  path: PATH.CREDITS,
  component: creditsScreen,
  icon: AttachMoney,
  exact: true,
};

export const CARTS_ROUTE: RouteModel = {
  title: 'MENU.CARTS',
  subtitle: 'SCREEN.ACCOUNT.CARTS',
  path: PATH.CARTS,
  icon: ShoppingCart,
  component: CartsScreen,
};

export const MY_FILES_ROUTES: RouteModel = {
  title: 'MENU.FILES',
  subtitle: 'SCREEN.ACCOUNT.FILES',
  path: PATH.MY_FILES,
  icon: FolderShared,
  feature: 'file',
  component: MyFilesScreen,
};

export const MY_VENDORS_ROUTES: RouteModel = {
  title: 'MENU.VENDORS',
  subtitle: 'SCREEN.ACCOUNT.VENDORS',
  path: PATH.MY_VENDORS,
  feature: 'showVendor',
  icon: StoreMallDirectory,
  component: MyVendorsScreen,
};

export const USERS_ROUTE: RouteModel = {
  title: 'MENU.USERS',
  subtitle: 'SCREEN.ACCOUNT.USERS',
  path: USERS,
  icon: Group,
  accessLevel: 1,
  component: UsersScreen,
};

export const USER_ROUTE: RouteModel = {
  path: USERS,
  param: ':userId',
  defaultId: '0',
  component: UserScreen,
  accessLevel: 1,
  exact: true,
};

export const DAILY_REPORT_ROUTE: RouteModel = {
  path: PATH.DAILY_REPORT,
  param: ':reportDate?',
  defaultId: '',
  title: 'MENU.DAILY_REPORT',
  subtitle: 'SCREEN.ACCOUNT.DAILY_REPORT',
  icon: Today,
  feature: 'dailyReport',
  component: DailyReportScreen,
};

export const DAILY_REPORTS_ROUTE: RouteModel = {
  path: PATH.DAILY_REPORTS,
  component: DailyReportsScreen,
};

export const TOS_ROUTE: RouteModel = {
  path: PATH.TOS,
  component: TosScreen,
};

export const LIST: Array<RouteModel> = [
  ACCOUNT_ROUTE,
  HISTORY_ROUTE,
  INVOICES_ROUTE,
  RECEIVING_ROUTE,
  CARTS_ROUTE,
  PROFILE_ROUTE,
  MY_FILES_ROUTES,
  MY_VENDORS_ROUTES,
  USERS_ROUTE,
  USER_ROUTE,
  TOS_ROUTE,
  DAILY_REPORT_ROUTE,
];

export const ACCOUNT_LIST: Array<RouteModel> = [
  ACCOUNT_ROUTE,
  HISTORY_ROUTE,
  RECEIVING_ROUTE,
  INVOICES_ROUTE,
  CARTS_ROUTE,
  MY_FILES_ROUTES,
  MY_VENDORS_ROUTES,
  USERS_ROUTE,
  // SETTINGS_ROUTE,
  PROFILE_ROUTE,
];
