import React from 'react';
import { connect } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { screen } from '$fcomponents/hoc';
import { orderActions } from '$fbusiness/redux/order';
import { Link } from '$gcomponents/primitives';
import { ROUTES } from '$business/enums';

import { IonPageWrapper } from './styles';
import OrderModel from '$fbusiness/models/order';
import { Div, SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import intl from '$intl';
import CartList from '$components/cartList';
import { OrderSummary } from '$fcomponents';
import CurrentStateModel from '$fbusiness/models/currentState';
import IonContentWrapper from '$fcomponents/ionContentWrapper';
import { Header } from '$gcomponents/widgets';
import RefundModel from '$fbusiness/models/refund';
import { cartActions } from '$fbusiness/redux/cart';

interface ThankyouScreenProps {
  onHydrate;
  onDehydrate;
  currentState: CurrentStateModel;
  order: OrderModel;
  refund: RefundModel;
}

const ThankyouScreen: React.FC<ThankyouScreenProps> = ({ order, refund, currentState }) => {
  if (!order && !refund) return <IonPageWrapper></IonPageWrapper>;

  const { hidePrice } = currentState;
  const price = {
    subtotal: order?.subtotal || 0,
    total: order?.total || 0,
    tax: order?.tax || 0,
    delivery: 0, // TODO this should come from order
  };
  const refundPrice = {
    subtotal: refund?.subtotal || 0,
    total: refund?.total || 0,
    tax: refund?.tax || 0,
  };
  return (
    <IonPageWrapper>
      <Header backRoute={ROUTES.DEFAULT_APP_PATH} backText="Back to Home" />
      <IonContentWrapper>
        <Div padding={SPACE.XLARGE} margin="auto" maxWidth="600px">
          <Flex className="title">
            <CheckCircleIcon />
            <div>{intl('SCREEN.THANKYOU.TITLE')}</div>
          </Flex>
          {order && (
            <div className="po-container">
              <div className="subtitle">{intl('SCREEN.ORDER.SUMMARY.TITLE')}</div>
              <CartList includeHeader items={order?.items || []} hideImage hidePrice={hidePrice} />
              {order.note && <Div padding={SPACE.LARGE}>{`Note: "${order.note}"`}</Div>}
              <Flex justifyContent="flex-end">
                <div className="summary-wrapper">{!hidePrice && <OrderSummary price={price} />}</div>
              </Flex>
            </div>
          )}
          {refund && (
            <div className="refund-container">
              <div className="subtitle">{intl('SCREEN.ORDER.SUMMARY.TITLE_REFUND')}</div>
              <CartList includeHeader items={refund.items || []} hideImage hidePrice={hidePrice} />
              {refund.note && <Div padding={SPACE.LARGE}>{`Note: "${refund.note}"`}</Div>}
              <Flex justifyContent="flex-end">
                <div className="summary-wrapper">{!hidePrice && <OrderSummary price={refundPrice} />}</div>
              </Flex>
            </div>
          )}
          <div className="home-link">
            <Link route={ROUTES.DEFAULT_APP_PATH} routerDirection="root">
              Back to Home
            </Link>
          </div>
        </Div>
      </IonContentWrapper>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  order: state.order.order,
  refund: state.order.refund,
});

const mapDispatchToProps = (dispatch) => ({
  onHydrate: (params) => {
    dispatch(orderActions.fetchOrder(parseInt(params.orderId || 0)));
    dispatch(orderActions.fetchRefund(parseInt(params.refundId || 0)));
  },
  onDehydrate: () => {
    dispatch(orderActions.dehydrate());
    dispatch(cartActions.resetCart());
  },
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ThankyouScreen));
