import ItemModel from '../../models/item';
import ModifierGroupModel from '../../models/modifierGroup';

export const ITEM_FAILURE = 'ITEM_FAILURE';
export const FETCH_ITEM_SUCCESS = 'FETCH_ITEM_SUCCESS';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_MOCK_ITEMS_SUCCESS = 'FETCH_MOCK_ITEMS_SUCCESS';
export const FETCH_MG_SUCCESS = 'FETCH_MG_SUCCESS';
export const CREATE_ITEM_SUCCESS = 'SAVE_ITEM_SUCCESS';
export const UPDATE_ITEM_SUCCESS = 'SAVE_ITEM_SUCCESS';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const UPDATE_MODIFIER_GROUP = 'UPDATE_MODIFIER_GROUP';
export const DELETE_MODIFIER_GROUP = 'DELETE_MODIFIER_GROUP';
export const REORDER_ITEM = 'REORDER_ITEM';
export const CLEAN_ITEM = 'CLEAN_ITEM';
export const CLEAN_MOCK = 'CLEAN_MOCK';
export const TOGGLE_PROP = 'ITEM_TOGGLE_PROP';
export const TOGGLE_SETTING_PROP = 'ITEM_TOGGLE_SETTING_PROP';

// Action Types
export interface typeOnlyType {
  type:
    | typeof CREATE_ITEM_SUCCESS
    | typeof UPDATE_ITEM_SUCCESS
    | typeof CLEAN_ITEM
    | typeof CLEAN_MOCK
    | typeof DELETE_ITEM_SUCCESS;
}

export interface failureType {
  type: typeof ITEM_FAILURE;
  err: string;
}

export interface UpdateModifierGroupType {
  type: typeof UPDATE_MODIFIER_GROUP;
  index: number;
  modifierGroup: ModifierGroupModel;
}

export interface TogglePropType {
  type: typeof TOGGLE_PROP | typeof TOGGLE_SETTING_PROP;
  id: number;
  value: any;
}
export interface DeleteModifierGroupType {
  type: typeof DELETE_MODIFIER_GROUP;
  index: number;
}

export interface FetchItemSuccessType {
  type: typeof FETCH_ITEM_SUCCESS;
  item: ItemModel;
}

export interface FetchItemsSuccessType {
  type: typeof FETCH_ITEMS_SUCCESS;
  items: Array<ItemModel>;
}

export interface FetchMockItemsSuccessType {
  type: typeof FETCH_MOCK_ITEMS_SUCCESS;
  items: Array<ItemModel>;
}

export interface FetchMgSuccessType {
  type: typeof FETCH_MG_SUCCESS;
  modifierGroups: Array<ModifierGroupModel>;
}

export interface ReorderItemType {
  type: typeof REORDER_ITEM;
  itemId: number;
  rank: number;
}

export interface ItemReducerType {
  items: Array<ItemModel>;
  item: ItemModel | null;
  mockItems: Array<ItemModel>;
  modifierGroups: Array<ModifierGroupModel>;
  isFinished: boolean;
  refresh: boolean;
}

export type ItemActionTypes =
  | typeOnlyType
  | failureType
  | FetchItemSuccessType
  | FetchItemsSuccessType
  | FetchMockItemsSuccessType
  | FetchMgSuccessType
  | UpdateModifierGroupType
  | TogglePropType
  | ReorderItemType
  | DeleteModifierGroupType;

export const ITEM_INIT_STATE: ItemReducerType = {
  item: null,
  items: [],
  mockItems: [],
  modifierGroups: [],
  isFinished: false,
  refresh: true,
};
