import styled from 'styled-components';
import { Modal } from '$gcomponents/reusables';
import { FONT, SPACE, WEIGHT } from '$gstyles';

export const ModalWrapper = styled(Modal)`
  ion-content {
    --background: var(--ion-color-primary) !important;
  }
`;

export const StoreItemWrapper = styled.div`
  border-radius: 10px;
  background-color: #fff;
  padding: ${SPACE.LARGE};
  margin: ${SPACE.LARGE} auto;
  min-height: 60px;
  max-width: 500px;
  cursor: pointer;

  .store-name {
    font-size: ${FONT.LARGE};
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
  .address {
    opacity: 0.8;
  }
`;
