import StoreModel from '../../models/store';

export const STORE_FAILURE = 'STORE_FAILURE';
export const STORE_STORE_SUCCESS = 'FETCH_STORE_SUCCESS';
export const CREATE_STORE_SUCCESS = 'SAVE_STORE_SUCCESS';
export const UPDATE_STORE_SUCCESS = 'SAVE_STORE_SUCCESS';
export const DELETE_STORE_SUCCESS = 'DELETE_STORE_SUCCESS';
export const CLEAN_STORE = 'CLEAN_STORE';
export const UPDATE_CREDIT_SUCCESS = 'UPDATE_CREDIT_SUCCESS';
export const FETCH_STORE_SUCCESS = 'FETCH_STORE_SUCCESS';
export const FETCH_STORES_SUCCESS = 'FETCH_STORES_SUCCESS';
export const RESET_FINISHED = 'STORE_RESET_FINISHED';
export const UPDATE_OPEN_BALANCE = 'UPDATE_OPEN_BALANCE';

// Action Types
export interface typeOnlyType {
  type:
    | typeof CREATE_STORE_SUCCESS
    | typeof UPDATE_STORE_SUCCESS
    | typeof CLEAN_STORE
    | typeof DELETE_STORE_SUCCESS
    | typeof RESET_FINISHED;
}

export interface failureType {
  type: typeof STORE_FAILURE;
  err: string;
}

export interface UpdateOpenBalanceType {
  type: typeof UPDATE_OPEN_BALANCE;
  storeId: number;
  balance: number;
}

export interface FetchStoreSuccessType {
  type: typeof FETCH_STORE_SUCCESS;
  store: StoreModel;
}

export interface UpdateCreditSuccessType {
  type: typeof UPDATE_CREDIT_SUCCESS;
  credits: number;
  storeId: number;
}

export interface FetchStoreSuccessType {
  type: typeof FETCH_STORE_SUCCESS;
  store: StoreModel;
}

export interface FetchStoresSuccessType {
  type: typeof FETCH_STORES_SUCCESS;
  stores: Array<StoreModel>;
}

export interface StoreReducerType {
  store: StoreModel | null;
  stores: Array<StoreModel>;
  isFinished: boolean;
  refresh: boolean;
}

export type StoreActionTypes =
  | typeOnlyType
  | failureType
  | FetchStoresSuccessType
  | FetchStoreSuccessType
  | UpdateCreditSuccessType
  | UpdateOpenBalanceType
  | FetchStoreSuccessType;

export const STORE_INIT_STATE: StoreReducerType = {
  store: null,
  stores: [],
  isFinished: false,
  refresh: true,
};
