import React from 'react';
import { IonRouterOutlet } from '@ionic/react';

import { ROUTES } from '$business/enums';
import { canAccess } from '$gbusiness/helpers/util';
import CurrentStateModel from '$fbusiness/models/currentState';

import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import UserModel from '$gbusiness/models/user';
import { Redirect, Route } from 'react-router';

interface RouterProps {
  currentState: CurrentStateModel;
  user?: UserModel;
  isLoggedIn: boolean;
}

const AppOutlet: React.FC<RouterProps> = ({ isLoggedIn, currentState, user }) => {
  return (
    <IonRouterOutlet id="main" animated={true}>
      {ROUTES.PRIVATE_LIST.map((menu, i) => (
        <PrivateRoute
          key={i}
          user={user}
          exact={menu?.exact || true}
          path={`${menu.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          currentState={currentState}
          feature={menu.feature}
          isAccessible={canAccess(menu.accessLevel, user?.userLevel)}
        />
      ))}
      {ROUTES.PUBLIC_LIST.map((menu, i) => (
        <PublicRoute
          key={i}
          user={user}
          currentState={currentState}
          path={`${menu?.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          exact={menu.exact || true}
        />
      ))}
      <Route
        path={ROUTES.DEFAULT_PUBLIC_PATH}
        component={isLoggedIn ? undefined : ROUTES.DEFAULT_PUBLIC_ROUTE.component}
        render={isLoggedIn ? () => <Redirect to={ROUTES.DEFAULT_APP_PATH} exact /> : undefined}
        exact
      />
    </IonRouterOutlet>
  );
};

export default AppOutlet;
