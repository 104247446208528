import React from 'react';
import { Formik } from 'formik';
import { input as inputHelper } from '$gbusiness/helpers';
import { util } from '$fbusiness/helpers';

import { DATE_FORMATS } from '$gbusiness/enums';
import { DATE_RANGE, DATE_RANGE_TYPE } from '$fbusiness/enums/options/dateRange';
import { REPORT_TYPE } from '$fbusiness/enums/options/reportType';
import intl from '$intl';
import { FilterWrapper } from './styles';
import { FILTER_FORM, DATE_FILTER_FORM, REPORT_TYPE_FORM } from './filterForm';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import { Flex } from '$gstyles/wrapper';
import { datetimeToStringUtc } from '$gbusiness/helpers/date';
import { parseISO } from 'date-fns/esm';
import { useDispatch } from 'react-redux';
import { localStorageActions } from '$gbusiness/redux/localStorage';
import StoreModel from '$fbusiness/models/store';
import { Refresh } from '@mui/icons-material';

interface ReportFilterProps {
  onSearch: Function;
  excludeQuery?: boolean;
  useDatetime?: boolean;
  parentFilter?: filterProps;
  onChangeStore?: Function;
  defaultRange?: DATE_RANGE_TYPE;
  placeholder?: string;
  includeReportType?: boolean;
  excludeDates?: boolean;
  includeRefresh?: boolean;
  extraForm?: any;
  extraForm2?: any;
  stores?: Array<any>;
  dateOnly?: boolean;
  filterWidth?: string;
}

export interface filterProps {
  query: string;
  store?: StoreModel;
  dateStart: string | null;
  dateEnd: string | null;
  datePeriod?: string;
  reportType?: string;
  forceRefresh?: boolean;
  top?: string;
  types?: Array<any>;
  customFilter?: any;
}

export const defaultFilter: filterProps = {
  query: '',
  dateStart: null,
  dateEnd: null,
  reportType: REPORT_TYPE.ACCURAL,
  forceRefresh: false,
};

const ReportFilter: React.FC<ReportFilterProps> = ({
  parentFilter,
  onSearch,
  excludeQuery = false,
  stores,
  onChangeStore,
  extraForm,
  extraForm2,
  includeRefresh = false,
  excludeDates = false,
  defaultRange = DATE_RANGE.LAST_7_DAYS,
  dateOnly = false,
  includeReportType = false,
  placeholder,
  filterWidth = '',
}) => {
  const hasStores = stores && stores.length > 0;
  const dispatch = useDispatch();
  const [startDate, endDate] = util.getDateRangeFromPeriod(parentFilter?.datePeriod || defaultRange);
  const initialValues = {
    ...(parentFilter?.types && { types: parentFilter.types }),
    ...defaultFilter,
    datePeriod: defaultRange,
    dateStart: parentFilter?.dateStart === null ? null : startDate,
    ...(hasStores && { store: parentFilter?.store }),
    dateEnd: parentFilter?.dateEnd === null ? null : endDate,
    ...(parentFilter?.dateStart && { dateStart: parseISO(parentFilter.dateStart) }),
    ...(parentFilter?.dateEnd && { dateEnd: parseISO(parentFilter.dateEnd) }),
    ...(parentFilter?.datePeriod && { datePeriod: parentFilter.datePeriod }),
    ...(parentFilter?.datePeriod !== DATE_RANGE.CUSTOM && {
      dateStart: startDate,
      dateEnd: endDate,
    }),
    ...(parentFilter?.top && { top: parentFilter.top }),
    ...(parentFilter?.customFilter && { customFilter: parentFilter.customFilter }),
  };
  const onSubmit = (values) => {
    const { query, datePeriod, dateStart, dateEnd, reportType, top, types, customFilter } = values;
    const { region, user, termId, shippingMethodId, store } = values;
    const dateFilter = {
      datePeriod,
      dateStart: datetimeToStringUtc(dateStart, DATE_FORMATS.DATE),
      dateEnd: datetimeToStringUtc(dateEnd, DATE_FORMATS.DATE),
    };

    onSearch({
      ...parentFilter,
      datePeriod: undefined,
      dateStart: undefined,
      dateEnd: undefined,
      ...(customFilter && { customFilter }),
      ...(excludeDates ? {} : dateFilter),
      ...(termId && { termId }),
      ...(shippingMethodId && { shippingMethodId }),
      ...(region
        ? { region, regionId: region.id }
        : region === null && { region: undefined, regionId: undefined }),
      ...(user ? { user, userId: user.userId } : user === null && { user: undefined, userId: undefined }),
      ...(store ? { store, storeId: store.id } : store === null && { store: undefined, storeId: undefined }),
      ...(types && { types }),
      reportType,
      query,
      top,
    });
    dispatch(localStorageActions.setObj('dateFilter', dateFilter));
  };

  const filterForm = dateOnly
    ? DATE_FILTER_FORM()
    : FILTER_FORM({
        excludeDates,
        stores,
        excludeQuery,
        onChangeStore,
        placeholder,
        extraForm,
        extraForm2,
      });

  const validateForm = (values) => {
    return inputHelper.validateError(filterForm, values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <FilterWrapper className="report-filter" minWidth={filterWidth}>
          {includeReportType && (
            <FormSection
              FORM={REPORT_TYPE_FORM(onSubmit, parentFilter?.top)}
              formik={formik}
              marginBottom="0"
            />
          )}
          {/* {extraForm && <FormSection FORM={extraForm} formik={formik} marginBottom="0" />} */}
          <FormSection
            FORM={filterForm}
            formik={formik}
            lastColumn={
              <Flex justifyContent="space-between" flex={1}>
                <Button
                  onClick={formik.handleSubmit}
                  className="submit-button"
                  variant="contained"
                  size="large"
                  color="secondary">
                  {intl('BUTTON.SUBMIT')}
                </Button>
                {includeRefresh && (
                  <Button
                    onClick={formik.handleSubmit}
                    className="submit-button"
                    variant="outlined"
                    icon={<Refresh />}
                    size="large"
                    color="secondary">
                    {intl('BUTTON.REFRESH')}
                  </Button>
                )}
              </Flex>
            }
          />
        </FilterWrapper>
      )}
    </Formik>
  );
};

export default ReportFilter;
