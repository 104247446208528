import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';

import { authActions } from '$gbusiness/redux/auth';
import { ROUTES } from '$business/enums';

import Menu from '../../sideMenu';

import AppOutlet from '$product/_init/appOutlet';
import CategoryModel from '$fbusiness/models/category';
import { ORDER_LIST_PATHS } from '$business/enums/routes';
import FactoryModel from '$fbusiness/models/factory';
import { getFactoryLogo } from '$fbusiness/helpers/util';
import { IonReactRouter } from '@ionic/react-router';
import CurrentStateModel, { initialCurrentState } from '$fbusiness/models/currentState';
import UserModel from '$gbusiness/models/user';
import StoreModel from '$fbusiness/models/store';

interface RouterProps {
  factory: FactoryModel;
  store?: StoreModel;
  categories: Array<CategoryModel>;
  isLoggedIn: boolean;
  currentRoute: string;
  onLogout: Function;
  user?: UserModel;
}

const Router: React.FC<RouterProps> = ({
  factory,
  store,
  categories,
  isLoggedIn,
  currentRoute,
  user,
  onLogout,
}) => {
  if (!factory) return null;
  const redirectRoute = ROUTES.DEFAULT_APP_PATH;
  const isOrderRoute = !!ORDER_LIST_PATHS.find((r) => currentRoute.includes(r));

  const currentState: CurrentStateModel = {
    ...initialCurrentState,
    isLoggedIn,
    isSalesman: true, // isLoggedIn && !!stores?.length,
    isWholesale: isLoggedIn && store?.settings?.wholesale === '1' ? true : false,
    hidePrice: !!factory.settings?.hidePrice,
    ebrochure: !isLoggedIn,
    isFactory: false,
    store: store,
    user,
    settings: factory.settings,
  };

  return (
    <IonReactRouter basename={process.env.REACT_APP_PUBLIC_URL}>
      <Menu
        isOrderRoute={isOrderRoute}
        menuId="start"
        categories={categories}
        currentRoute={currentRoute}
        onLogout={onLogout}
        isLoggedIn={isLoggedIn}
        logo={getFactoryLogo(factory, true)}
      />
      <IonContent>
        <AppOutlet isLoggedIn={isLoggedIn} currentState={currentState} />
        {/* <Receipt isHidden={!isOrderRoute} currentRoute={currentRoute} isLoggedIn={isLoggedIn} /> */}
        <Route path="/" render={() => <Redirect to={redirectRoute} />} exact />
      </IonContent>
    </IonReactRouter>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  store: state.store?.store,
  categories: state.category.categories,
  isLoggedIn: state.localStorage.accessToken !== '',
  currentRoute: state.routing.currentRoute,
  user: state.currentUser,
});

const mapDispatchToProps = {
  onLogout: authActions.logout,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(Router);
