import {
  DAILY_REPORT_UPDATE_SUCCESS,
  DailyReportReducerType,
  DAILY_REPORT_INIT_STATE,
  DailyReportActionTypes,
  RESET_FINISHED,
  CLEAN_DAILY_REPORT,
  FETCH_DAILY_REPORT_SUCCESS,
} from './types';

export default function dailyReportReducer(
  state: DailyReportReducerType = DAILY_REPORT_INIT_STATE,
  action: DailyReportActionTypes,
): DailyReportReducerType {
  switch (action.type) {
    case DAILY_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case FETCH_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        dailyReport: action.dailyReport,
      };
    case RESET_FINISHED:
      return {
        ...state,
        isFinished: false,
      };
    case CLEAN_DAILY_REPORT:
      return {
        ...DAILY_REPORT_INIT_STATE,
      };
    default:
      return state;
  }
}
