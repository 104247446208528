import styled from 'styled-components';
import { IonHeader, IonPage } from '@ionic/react';

export const IonPageWrapper = styled(IonPage)`
  .table2 {
    padding-top: 0;

    span.value {
      display: inline-block;
    }

    .MuiTableRow-root.isNew {
      background-color: #b3cee2;
    }
  }
  ion-backdrop,
  .MuiBackdrop-root {
    opacity: 0.8;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 11;
    display: flex;
    align-items: center;
    font-size: 60px;
    color: #fff;

    .message {
      font-size: 36px;
      height: 60px;
    }
  }
  ion-backdrop.backdrop-hide {
    z-index: -1;
  }
`;

export const IonHeaderWrapper = styled(IonHeader)`
  height: 56px;
  .wrapper {
    padding: 0 16px;
    background-color: var(--ion-color-light);
    height: 100%;
  }

  .note-input {
    padding: 6px;
  }

  .add-button {
    border: 1px solid var(--ion-color-secondary);
    font-size: 2em;
    padding: 2px;
    color: var(--ion-color-secondary);
    margin-right: 8px;
    border-radius: 8px;
  }

  button {
    min-width: 100px;
  }
  .note-input {
    flex: 1;
  }

  .display-section {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 2px 8px;
    background-color: #f6f6f6;
    min-height: 36px;
    margin-right: 8px;
  }
`;
