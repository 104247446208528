import styled from 'styled-components';
import { IonPage } from '@ionic/react';

import { Col, FONT, SPACE, WEIGHT } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .page-wrapper {
    font-size: ${FONT.LARGE};
    max-width: 800px;
    margin: auto;

    .subtitle {
      font-weight: ${WEIGHT.SEMI_BOLD};
      color: var(--ion-color-primary);
    }

    .buttons-container {
      max-width: 600px;
      margin: ${SPACE.XLARGE} auto;
    }
  }
`;

export const MainButtonWrapper = styled(Col)`
  .button {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast-bw);
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    height: 150px;
    margin: 10px;
  }
`;
