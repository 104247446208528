import AddressModel from '$gbusiness/models/address';
import { toLocal } from '$gbusiness/helpers/date';
import { parseAddress, toNumber } from '$gbusiness/helpers/util';
import DiscountModel, { deriveRawToDiscount } from './discount';
import TermModel, { deriveRawToTerm } from './term';
import { deriveRawToUser } from '$gbusiness/models/user';

export default interface StoreModel {
  id: number;
  factoryId: number;
  code?: string;
  name: string;
  formatted?: string;
  address?: AddressModel | undefined;
  placeId?: string;
  email: string;
  phone: string;
  discounts?: Array<DiscountModel>;
  userIds?: Array<number>;
  regionId?: number;
  regionIds?: Array<number>;
  regionName?: string;
  credits?: number;
  termId?: number;
  terms?: Array<TermModel>;
  openBalance: number;
  salesmen?: Array<string>;
  settings: {
    taxPercent: 0;
    note?: string;
    wholesale: string;
    emailConfirmation: boolean;
    textConfirmation: boolean;
    discounts?: Array<number>;
    defaultDiscount?: number;
    shippingAddress?: any;
    shippingFormatted?: string;
    invoiceEmail: string;
  };
  createdAt?: string;
}

export interface ComplexStoreModel extends StoreModel {
  salesmen;
}

export interface SimpleStoreModel {
  id: number;
  name: string;
  formatted: string;
}

export const deriveRawToStore = (raw) => {
  return {
    id: raw.id,
    factoryId: raw.factoryId,
    code: raw.code || '',
    name: raw.name,
    formatted: raw.address,
    settings: {
      ...raw.settings,
      wholesale: raw.settings?.wholesale === '1' ? '1' : '0',
      taxPercent: toNumber(raw.settings?.taxPercent),
    },
    ...(raw.address && { address: parseAddress(raw.address) }),
    ...(raw.discounts && { discounts: raw.discounts.map(deriveRawToDiscount) }),
    ...(raw.terms && { terms: raw.terms.map(deriveRawToTerm) }),
    ...(raw.userIds && { userIds: raw.userIds }),
    ...(raw.regionId && { regionId: raw.regionId }),
    ...(raw.regionIds && { regionIds: raw.regionIds }),
    ...(raw.regionName && { regionName: raw.regionName }),
    ...(raw.termId && { termId: raw.termId }),
    openBalance: raw.openBalance || 0,
    salesmen: raw.salesmen.map(deriveRawToUser) || [],
    placeId: raw.placeId,
    credits: raw.credits || 0,
    email: raw.email,
    phone: raw.phone,
    createdAt: toLocal(raw.createdAt),
  };
};

export const initialStore: StoreModel = {
  id: 0,
  factoryId: 0,
  name: '',
  formatted: '',
  placeId: '',
  email: '',
  phone: '',
  credits: 0,
  openBalance: 0,
  settings: {
    taxPercent: 0,
    wholesale: '0',
    emailConfirmation: true,
    textConfirmation: false,
    invoiceEmail: '',
  },
};
