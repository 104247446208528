import CartModel from '$fbusiness/models/cart';

export function getCartPrices(cart: CartModel) {
  const { pricing } = cart;
  const { po, refund } = pricing;
  const afterDiscount = po.subtotal - po.totalDiscount;
  const refundAfterDiscount = refund.subtotal - refund.totalDiscount;
  return {
    listPrice: po.subtotal,
    price: afterDiscount,
    refund: refundAfterDiscount,
    subtotal: afterDiscount - refundAfterDiscount,
    tax: pricing.final.tax,
    total: pricing.final.total,
    discount: po.totalDiscount,
    savedRate: po.savedRate,
  };
}
