import { itemText } from '$gbusiness/helpers/util';
import { IonHeader, IonSearchbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import StoreItem from './storeItem';
import { ModalWrapper } from './styles';

interface StoreSelectorModalProps {
  show: boolean;
  stores: Array<any>;
  onClose: Function;
  onSelectStore: Function;
}

const StoreSelectorModal: React.FC<StoreSelectorModalProps> = ({ show, onClose, onSelectStore, stores }) => {
  const [query, setQuery] = useState('');
  const onClickStore = (id) => {
    onClose();
    onSelectStore(id);
  };

  useEffect(() => {
    if (show) setQuery('');
  }, [show, setQuery]);

  const filteredStores = stores.filter((s) => {
    if (s.name.toLowerCase().includes(query.toLowerCase())) return true;
    if ((s.address || '').toLowerCase().includes(query.toLowerCase())) return true;
    return false;
  });

  return (
    <ModalWrapper title="SCREEN.STORES.TITLE" show={show} onClose={() => onClose()}>
      <IonHeader>
        <IonSearchbar
          value={query}
          onIonChange={(e) => setQuery(e?.detail?.value || '')}
          placeholder={itemText('SEARCH', 'STORE')}
        />
      </IonHeader>
      <div>
        {filteredStores.length < 300 &&
          filteredStores.map((s) => <StoreItem key={s.id} store={s} onSelect={() => onClickStore(s.id)} />)}
      </div>
    </ModalWrapper>
  );
};

export default StoreSelectorModal;
