import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { currency } from '$gbusiness/helpers/util';
import ExportModel from '$gbusiness/models/export';

export const arReportExportSettings: ExportModel = {
  url: configs.api.report.ar,
  method: 'POST',
  fileName: 'arReport',
  deriveToModel: (row) => row,
  deriveTable: (item) => ({
    CustomerName: item.storeName,
    Current: item.balanceToday,
    Last30: item.balance30,
    From_31_60: item.balance60,
    From_60_90: item.balance90,
    Beyond_90: item.balance90p,
  }),
  sortKey: 'name',
  sortOrder: SORT.ASC,
};

export const AR_REPORT_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.report.ar,
    method: 'POST',
    defaultSortKey: 'name',
    defaultSortOrder: SORT.ASC,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 600,
    maxWidth: 800,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 44,
  },
};

export const AR_REPORT_TABLE = (handleClick): Array<CellModel> => [
  {
    label: 'SCREEN.REPORT.COLS.STORE_NAME',
    value: 'name',
    alwaysShow: true,
    className: 'main',
    component: (row) => (
      <div className="link" onClick={() => handleClick(row.id)}>
        {row.storeName}
      </div>
    ),
    sortable: SORT.ASC,
    width: 400,
  },
  {
    label: 'SCREEN.REPORT.COLS.AR_TODAY',
    value: 'balanceToday',
    align: 'center',
    component: (row) => <>{currency(row.balanceToday)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.AR_30',
    value: 'balance30',
    align: 'center',
    component: (row) => <>{currency(row.balance30)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.AR_60',
    value: 'balance60',
    align: 'center',
    component: (row) => <>{currency(row.balance60)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.AR_90',
    value: 'balance90',
    align: 'center',
    component: (row) => <>{currency(row.balance90)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.AR_90p',
    value: 'balance90p',
    align: 'center',
    component: (row) => <>{currency(row.balance90p)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
];
