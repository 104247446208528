import { IonToolbar, IonButtons, IonTitle, IonBackButton, IonMenuButton } from '@ionic/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExitToApp } from '@mui/icons-material';

import intl from '$gintl';
import { authActions } from '$gbusiness/redux/auth';
import CurrentStateModel from '$fbusiness/models/currentState';

import { IonHeaderWrapper } from './styles';
import StoreSelectorModal from '../../screens/storeSelectorModal';
import { storeActions } from '$fbusiness/redux/store';
import { cartActions } from '$fbusiness/redux/cart';

interface MainHeaderProps {
  showModal?: boolean;
  onSetModal?: Function;
  onSelectStore?: Function;
  title?: string;
  titleText?: string;
  showTitle?: boolean;
  currentState: CurrentStateModel;
  hasBack?: boolean;
}

const MainHeader: React.FC<MainHeaderProps> = ({
  showModal: showModalParent,
  onSelectStore: onSelectStoreParent,
  onSetModal: onSetModalParent,
  currentState,
  title = '',
  titleText = '',
  showTitle = false,
  hasBack = false,
}) => {
  const isControlled = showModalParent !== undefined;
  const titleDisplay = showTitle
    ? titleText || intl(title)
    : currentState?.store
    ? currentState?.store.name
    : titleText || intl(title);
  const dispatch = useDispatch();
  const [showSelector, setShowSelector] = useState(false);

  const showModal = isControlled ? showModalParent : showSelector;
  const onSetModal = isControlled ? onSetModalParent : setShowSelector;

  const stores = useSelector((state: any) => state.store.stores);

  const navIcon = hasBack ? <IonBackButton /> : <IonMenuButton></IonMenuButton>;

  const onSelectStore = async (id) => {
    await dispatch(storeActions.fetchStore(id, true));
    await dispatch(cartActions.clearDiscount());
    if (onSetModal) onSetModal(false);
    if (onSelectStoreParent) onSelectStoreParent();
  };

  const onLogout = () => {
    dispatch(authActions.logout());
  };

  return (
    <>
      <IonHeaderWrapper id="sidemenu">
        <IonToolbar color="medium">
          <IonButtons slot="start">{navIcon}</IonButtons>
          <IonTitle>
            <span
              className="pointer"
              onClick={() => {
                if (onSetModal && !showTitle) onSetModal(true);
              }}>
              {titleDisplay}
            </span>
          </IonTitle>
          <IonButtons slot="end">
            <div className="link uppercase login" onClick={() => onLogout()}>
              <ExitToApp fontSize="large" />
              <div className="label">{intl('BUTTON.LOGOUT')}</div>
            </div>
          </IonButtons>
        </IonToolbar>
        <StoreSelectorModal
          show={showModal || false}
          stores={stores}
          onClose={() => {
            if (onSetModal) onSetModal(false);
          }}
          onSelectStore={onSelectStore}
        />
      </IonHeaderWrapper>
      {/* <SearchBarWrapper>
        <ItemListContainer currentState={currentState}>
          {(onAddCart, onViewItem) => (
            <Flex className="search-wrapper">
              <Autocomplete
                endpoint={configs.api.item.search}
                AC={{
                  ...AUTO_COMPLETE,
                  extraParam: { ebrochure, factoryId: getPublicFactoryId() },
                  onSelect: value => {
                    if (value.sku == null) {
                      history.push(PATH.MENU + '/' + value.id);
                    } else {
                      onViewItem(value.id);
                    }
                  },
                }}
                width="300px"
                height="32px"
              />
            </Flex>
          )}
        </ItemListContainer>
      </SearchBarWrapper> */}
    </>
  );
};

export default MainHeader;
