import styled from 'styled-components';

export const CounterWrapper = styled.div`
  height: ${(p) => (p.size ? p.size * 2 : 32)}px;
  width: ${(p) => (p.size ? p.size * 8 : 128)}px;
  overflow: hidden;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-between;

  .MuiIconButton-root {
    padding: 3px;
  }
  div {
    flex: 1;
    font-size: ${(p) => (p.size ? p.size : 24)}px;
  }

  input {
    height: 32px;
    text-align: center;
    width: 44px;
  }
  svg {
    font-size: ${(p) => (p.size ? p.size : 24)}px;
  }
  .disabled {
    opacity: 0.4;
  }
  button {
    color: #347cc7;
    background-color: #fafafa;
    border-radius: 8px;
    border: 1px solid #347cc7;
  }
  .counter-field {
    width: 40px;
  }

  svg {
    border-radius: 6px;
    font-size: 28px;
    cursor: pointer;
    border: 1px solid ${(p) => p.color || '#333'};
  }
`;
