import { CounterWrapper } from './styles';
import { Add, Remove } from '@mui/icons-material';

interface CounterInputProps {
  id?: number;
  value?: number;
  minValue?: number;
  maxValue?: number;
  onChange: Function;
  onEnter?: any;
  disabled?: boolean;
  onInvalid?: Function;
  className?: string;
}

const CounterInput: React.FC<CounterInputProps> = ({
  id = 0,
  value = 1,
  minValue = 0,
  maxValue = 999,
  className = '',
  onInvalid,
  onEnter,
  disabled,
  onChange,
}) => {
  const changeQuantity = (num, hasDecreased = false) => {
    const newValue = value + num;
    if (newValue < minValue || newValue > maxValue) return;
    onChange(newValue, hasDecreased);
  };

  const onChangeInput = (e) => {
    const val = e.target.value;
    if (!val || isNaN(val) || val > 9999) {
      onChange(value);
      if (onInvalid) onInvalid(val);
      return;
    }
    onChange(Math.floor(val), undefined);
  };

  const onKeyUp = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode === 13 && onEnter) {
      onEnter(value);
    }
  };

  return (
    <CounterWrapper className={`counter-input ${className}`}>
      <Remove
        onClick={() => {
          changeQuantity(-1, true);
        }}
        className={disabled ? 'disabled' : ''}
      />
      <input
        className={`counter-field ${disabled && ''}`}
        data-id={id}
        type="number"
        step="1"
        disabled={disabled}
        value={value}
        onBlur={onEnter}
        onChange={onChangeInput}
        onKeyUp={onKeyUp}
      />
      <Add
        onClick={() => {
          changeQuantity(1);
        }}
        className={disabled ? 'disabled' : ''}
      />
    </CounterWrapper>
  );
};

export default CounterInput;
