import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import intl from '$intl';
import CounterInput from '$fcomponents/counterInput';

export const PO_TABLE_CONFIG: TableModel = {
  dataSource: defaultDataSource,
  styles: {
    ...defaultStyles,
    responsive: true,
    color: COLORS.MEDIUM,
    fontSize: '1.05em',
    minWidth: 800,
    maxWidth: 1200,
    cellPadding: ` 2px 4px`,
    headerHeight: 56,
    isStripped: false,
    borderColor: 'var(--ion-border-color)',
    disableSort: true,
    setRowClass: (row) => (row.settings?.isNew ? 'isNew' : ''),
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const PO_TABLE = (isWholesale, changeQuantity): Array<CellModel> => [
  {
    label: 'SCREEN.PO.COLS.TYPE',
    value: 'name',
    className: 'main',
    component: (row) => (
      <div className={`tag ${row.settings?.isRefund ? 'tertiary filled' : ''}`}>
        {intl('SCREEN.PO.' + (row.settings?.isRefund ? 'REFUND' : 'PURCHASE'))}
      </div>
    ),
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 140,
  },
  {
    label: 'SCREEN.PO.COLS.SKU',
    value: 'sku',
    className: 'desktop',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 160,
  },
  {
    label: 'SCREEN.PO.COLS.ITEM_NAME',
    value: 'name',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 240,
  },
  {
    label: 'SCREEN.PO.COLS.CATEGORY',
    value: 'category',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 240,
  },
  {
    label: 'SCREEN.PO.COLS.UPC',
    value: 'barcode',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 240,
  },
  {
    label: 'SCREEN.PO.COLS.RETAIL_PRICE',
    value: 'retailPrice',
    alwaysShow: true,
    align: 'center',
    className: 'desktop',
    isHidden: isWholesale,
    component: (row) => <>{intl('PRICE', { amount: row.price.retailPrice || row.price.original })}</>,
    sortable: SORT.ASC,
    width: 180,
  },
  {
    label: 'SCREEN.PO.COLS.WHOLESALE_PRICE',
    value: 'wholesalePrice',
    alwaysShow: true,
    align: 'center',
    component: (row) => <>{intl('PRICE', { amount: row.price.wholesalePrice })}</>,
    isHidden: !isWholesale,
    sortable: SORT.ASC,
    width: 180,
  },
  {
    label: 'SCREEN.PO.COLS.QTY',
    value: 'quantity',
    align: 'center',
    component: (row, actions, index) => (
      <div>
        <CounterInput
          className={row.error && 'error'}
          onChange={(q, hasDecreased) => changeQuantity(index, q, hasDecreased)}
          value={row.qty}
        />
      </div>
    ),
    sortable: SORT.ASC,
    minWidth: 150,
    maxWidth: 150,
  },
  // {
  //   label: 'SCREEN.ITEMS.COLS.ACTION',
  //   value: '',
  //   className: 'action',
  //   align: 'center',
  //   component: (row, actions) => {
  //     return (
  //       <Button
  //         className="left"
  //         fullWidth
  //         onClick={e => actions.handlePopoverOpen(e, { ...row, route: PATH.ITEMS })}>
  //         <MoreHorizIcon />
  //       </Button>
  //     );
  //   },
  //   width: 100,
  // },
];
