import React from 'react';
import { Formik } from 'formik';

import { input as inputHelper } from '$gbusiness/helpers';
import intl from '$intl';
import STORE_FILTER_FORM from './storeFilterForm';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import StoreModel from '$fbusiness/models/store';

interface StoreFilterProps {
  onChange: Function;
  stores: Array<StoreModel>;
  store?: StoreModel;
  parentFilter?: any;
  hasSearchButton?: boolean;
  inputSize?: string;
}

export const defaultStoreFilter = {
  forceRefresh: true,
};

const StoreFilter: React.FC<StoreFilterProps> = ({
  stores,
  store,
  onChange,
  hasSearchButton = false,
  inputSize = 'medium',
}) => {
  const initialValues = {
    store,
  };
  const onSubmit = (values) => {
    onChange({
      ...values,
      store: values?.store,
    });
  };
  const onChangeStore = (values, value) => {
    onChange(value);
  };

  const filterForm = STORE_FILTER_FORM({ stores, onChangeStore, options: { size: inputSize, gridSize: 12 } });

  const validateForm = (values) => {
    return inputHelper.validateError(filterForm, values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <FormSection
          FORM={filterForm}
          formik={formik}
          lastColumn={
            hasSearchButton && (
              <Button
                onClick={formik.handleSubmit}
                className="submit-button"
                variant="contained"
                size="large"
                color="secondary">
                {intl('BUTTON.SUBMIT')}
              </Button>
            )
          }
        />
      )}
    </Formik>
  );
};

export default StoreFilter;
