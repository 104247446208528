import { mergeObjectStrict, toNumber } from '$gbusiness/helpers/util';

export interface PriceModel {
  discountRate: number;
  taxRate: number;
  commRate: number;

  subtotal: number;
  itemDiscount: number;
  itemTaxable: number;
  itemTax: number;
  itemTotal: number;

  delivery: number;
  shipping: number;
  otherDiscount: number;
  totalDiscount: number;
  taxable: number;
  otherTax: number;

  tax: number;
  qty: number;
  tip: number;
  refundAmt: number;
  total: number;
  savedRate: number;
}

export const initialPrice: PriceModel = {
  discountRate: 0,
  taxRate: 0,
  commRate: 0,
  subtotal: 0,
  itemDiscount: 0,
  itemTax: 0,
  itemTaxable: 0,
  itemTotal: 0,
  shipping: 0,
  otherDiscount: 0,
  totalDiscount: 0,
  taxable: 0,
  otherTax: 0,
  tax: 0,
  tip: 0,
  delivery: 0,
  refundAmt: 0,
  qty: 0,
  total: 0,
  savedRate: 0,
};

export const deriveRawToPrice = (raw) => {
  return mergeObjectStrict(initialPrice, raw);
};

export interface ItemPriceModel {
  unitPrice: number;
  unitTax: number;
  subtotal: number;
  discount: number;
  taxable: number;
  tax: number;
  total: number;
  taxRate: number;
  discountRate: number;
  qty: number;
}

export const initialItemPrice = {
  unitPrice: 0,
  unitTax: 0,
  subtotal: 0,
  discount: 0,
  taxable: 0,
  tax: 0,
  total: 0,
  taxRate: 0,
  discountRate: 0,
  qty: 0,
};

export const deriveRawToItemPrice = (raw) => {
  return {
    ...initialItemPrice,
    ...raw,
    taxRate: toNumber(raw.taxRate) || 0,
    discountRate: toNumber(raw.discountRate) || 0,
  };
};

export const deriveItemPrice = (item) => {
  const { subtotal, discount, taxable, unitPrice, unitTax, tax, taxRate, discountRate, qty, total } = item;
  return {
    unitPrice,
    unitTax,
    subtotal,
    discount,
    taxable,
    tax,
    total,
    taxRate,
    discountRate,
    qty,
  };
};

export const derivePrice = (orderInvoice) => {
  const {
    subtotal,
    totalDiscount: discount,
    otherDiscount,
    shipping,
    itemTaxable,
    taxable,
    tax,
    qty,
    delivery,
    refundAmt,
    tip,
    total,
  } = orderInvoice;
  return {
    subtotal,
    discount,
    totalDiscount: discount,
    itemTaxable,
    otherDiscount,
    shipping,
    taxable,
    tax,
    delivery,
    refundAmt,
    qty,
    tip,
    total,
  };
};
