import { IonSelectOption } from '@ionic/react';
import MenuItem from '@mui/material/MenuItem';

import { INPUT } from '$gbusiness/enums';
import {
  MTextInput,
  MSelectInput,
  MDateInput,
  TextInput as ITextInput,
  SelectInput as ISelectInput,
  DateInput as IDateInput,
  MCheckbox as Checkbox,
  HiddenInput,
  Text,
  GoogleAddress,
  ButtonSelect,
  Switch,
} from '$gcomponents/primitives';
import { configs } from '$configs';
import ImageInput from '../reusables/imageInput';
import RadioButton from '../primitives/radioButton';
import QuantityInput from '../primitives/quantityEdit/material';
import MultiCheck from '../primitives/multiCheck';
import intl from '$gintl';
import AutoCompleteInput from '../primitives/autocomplete';

// Generates and returns correct JSX component given a form field meta data
export const generateInput = (
  input,
  formik,
  inputType = configs.display.inputType === 'undefined' ? undefined : configs.display.inputType,
  callback: Function = () => {},
) => {
  const { values, setFieldValue } = formik;
  const filter = input.condition ? { ...input, ...input.condition(formik.values) } : { ...input };

  const onChangeInput = (e, newValue = null, prevValue = null) => {
    if (!filter.onChange) return;
    const value = newValue !== null ? newValue : e.target.value;
    filter.onChange({
      value,
      newValue,
      prevValue,
      formValues: values,
      setFieldValue,
      callback,
      formik,
    });
  };
  const onBlur = (e, newValue = null) => {
    if (!filter.onBlur) return;
    const value = newValue !== null ? newValue : e.target.value;
    filter.onBlur({
      value,
      callback,
    });
  };
  const onKeyPress = (e, charCode) => {
    if (filter.onKeyPress) filter.onKeyPress(charCode, e.target.value);
  };

  const TextInput = filter.lib === 'ionic' ? ITextInput : MTextInput;
  const DateInput = filter.lib === 'ionic' ? IDateInput : MDateInput;
  const SelectInput = filter.lib === 'ionic' ? ISelectInput : MSelectInput;

  const commonProps = {
    formik,
    className: filter.className,
    label: filter.label,
    ...(filter.disableFastField && { disableFastField: filter.disableFastField }),
    ...(filter.labelText && { labelText: filter.labelText }),
    name: filter.value,
    size: filter.size,
    defaultValue: filter.defaultValue || undefined,
    handleChange: onChangeInput,
    readonly: filter.readonly,
    disabled: filter.disabled || (filter.isDisabled && filter.isDisabled(values)),
    placeholder: filter.placeholder || undefined,
    onClick: filter?.onClick,
  };

  const textProps = {
    icon: filter.icon,
    position: filter.position || inputType,
    maskType: filter.maskType,
    prefix: filter.prefix,
    postfix: filter.postfix,
    decimalPlaces: filter.decimalPlaces,
    enterToSubmit: filter.enterToSubmit,
    handleBlur: onBlur,
    handleKeyPress: onKeyPress,
    shrink: filter.shrink,
  };

  switch (filter.input) {
    case INPUT.HIDDEN:
      return (
        <HiddenInput
          name={filter.value}
          handleChange={onChangeInput}
          defaultValue={filter.defaultValue}
          formik={formik}
          className={filter.className}
        />
      );
    case INPUT.EMPTY:
      return <div />;
    case INPUT.IMAGE:
      return (
        <ImageInput
          cacheField={filter.cacheField}
          className={filter.className}
          height={filter.gridHeight}
          width={filter.gridWidth}
          size={filter.size}
          ratio={filter.ratio}
          label={filter.label}
          name={filter.value}
          formik={formik}
        />
      );
    case INPUT.TEXTAREA:
      return (
        <TextInput
          type={INPUT.TYPES.TEXTAREA}
          multiline
          rowsMax={filter.rowsMax}
          rows={filter.rows}
          maxLength={filter.maxLength}
          {...commonProps}
          {...textProps}
        />
      );
    case INPUT.ALPHANUMERIC:
      return (
        <TextInput
          type={INPUT.TYPES.TEXT}
          alphanumeric
          maxLength={filter.maxLength}
          {...commonProps}
          {...textProps}
        />
      );
    case INPUT.TEXT:
      return (
        <TextInput type={INPUT.TYPES.TEXT} maxLength={filter.maxLength} {...commonProps} {...textProps} />
      );
    case INPUT.AUTOCOMPLETE:
      return (
        <AutoCompleteInput {...commonProps} config={filter.config} clearValue={filter.defaultValue || null} />
      );
    case INPUT.QUANTITY:
      return (
        <QuantityInput
          {...commonProps}
          position={filter.position || inputType}
          defaultValue={filter.defaultValue}
          handleBlur={onBlur}
          shrink={filter.shrink}
        />
      );
    case INPUT.PASSWORD:
      return (
        <TextInput type={INPUT.TYPES.PASSWORD} maxLength={filter.maxLength} {...commonProps} {...textProps} />
      );
    case INPUT.NUMBERTEXT:
      return (
        <TextInput
          type={INPUT.TYPES.TEXT}
          inputMode={INPUT.MODES.NUMERIC}
          maxLength={filter.maxLength}
          {...commonProps}
          {...textProps}
        />
      );
    case INPUT.NUMBER:
      return <TextInput type={INPUT.TYPES.NUMBER} {...commonProps} {...textProps} />;
    case INPUT.PHONE:
      return (
        <TextInput type={INPUT.TYPES.TEXT} inputMode={INPUT.MODES.NUMERIC} {...commonProps} {...textProps} />
      );
    case INPUT.CURRENCY:
      return (
        <TextInput
          type={INPUT.TYPES.NUMBER}
          inputMode={INPUT.MODES.NUMERIC}
          {...(filter.maxAmount && { maxAmount: filter.maxAmount })}
          {...commonProps}
          {...textProps}
        />
      );
    case INPUT.EMAIL:
      return <TextInput type={INPUT.TYPES.EMAIL} {...commonProps} {...textProps} />;
    case INPUT.CHECKBOX:
      return <Checkbox slot={filter.slot} {...commonProps} />;
    case INPUT.SWITCH:
      return <Switch slot={filter.slot} {...commonProps} />;
    case INPUT.SELECT:
      return (
        <SelectInput
          shrink={filter.shrink}
          icon={filter.icon}
          position={filter.position || inputType}
          options={filter.options}
          native={!!filter?.native}
          {...commonProps}
        />
      );
    case INPUT.RADIO:
      return (
        <RadioButton
          options={filter.options}
          isMultiline={filter.isMultiline}
          position={filter.position || inputType}
          {...commonProps}
        />
      );
    case INPUT.MULTI_SELECT:
      return (
        <MultiCheck
          options={filter.options}
          selectAllText={filter.selectAllText}
          isMultiline={filter.isMultiline}
          position={filter.position || inputType}
          {...commonProps}
        />
      );
    case INPUT.BUTTON_SELECT:
      return (
        <ButtonSelect color={filter.color} icon={filter.icon} options={filter.options} {...commonProps} />
      );
    case INPUT.DATE:
      return (
        <DateInput
          shrink={filter.shrink}
          icon={filter.icon}
          position={filter.position || inputType}
          format={filter.format}
          minDate={filter.minDate}
          maxDate={filter.maxDate}
          enterToSubmit={filter.enterToSubmit}
          {...commonProps}
        />
      );
    case INPUT.ADDRESS:
      return (
        <GoogleAddress
          {...commonProps}
          requestOptions={filter.requestOptions}
          shrink={filter.shrink}
          position={filter.position || inputType}
        />
      );
  }
};

export const generateNativeOptions = (list, disable = false) => {
  return list.map((o, i) => (
    <option value={o.value} key={i} disabled={disable && !!o?.disabled}>
      {o.labelText !== undefined ? o.labelText : intl(`INPUT.OPTION.${o.label}`)}
    </option>
  ));
};

export const generateSelectOptions = (list, isMaterial = false, disable = false) =>
  isMaterial
    ? list.map((o, i) => (
        <MenuItem value={o.value} key={i} disabled={disable && !!o?.disabled}>
          {o.labelText ? o.labelText : <Text k={`INPUT.OPTION.${o.label}`} />}
        </MenuItem>
      ))
    : list.map((o, i) => (
        <IonSelectOption value={o.value} key={i}>
          {o.labelText ? o.labelText : <Text k={`INPUT.OPTION.${o.label}`} />}
        </IonSelectOption>
      ));
