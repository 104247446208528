import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$fcomponents/hoc';

import { IonPageWrapper } from './styles';
import MainHeader from '$components/mainHeader';
import CartModel from '$fbusiness/models/cart';
import FactoryModel from '$fbusiness/models/factory';
import StoreModel from '$fbusiness/models/store';
import { cartActions } from '$fbusiness/redux/cart';
import PoSearchBar from './poSearchBar';
import PoFooter from './poFooter';
import { PO_TABLE, PO_TABLE_CONFIG } from './table';
import { TableView2 } from '$gcomponents/widgets';
import ScanBackdrop from './scanBackdrop';
import { deriveRawToItem } from '$fbusiness/models/item';
import { sleep } from '$gbusiness/helpers/util';
import { checkCartStock, getFactoryCart, syncCart } from '$fbusiness/redux/cart/utils';
import { PageWrapper } from '$gstyles/wrapper';
import PATH from '$business/enums/paths';
import usePrevious from '$gbox/hooks/usePrevious';

interface PoScreenProps {
  factory: FactoryModel;
  store: StoreModel;
  history: any;
  cart: CartModel;
  currentState;
  addProduct: Function;
  changeQuantity: Function;
  removeProducts: Function;
  updateProduct: Function;
  updateProducts: Function;
  clearDiscount: Function;
  refreshCart: Function;
  setDiscount: Function;
}

const PoScreen: React.FC<PoScreenProps> = ({
  factory,
  history,
  store,
  cart: tempCart,
  currentState,
  addProduct,
  removeProducts,
  updateProduct,
  updateProducts,
  refreshCart,
  clearDiscount,
  setDiscount,
}) => {
  const [selections, setSelections] = useState<Array<any>>([]);
  const [filter, setFilter] = useState({ resetSelection: false });
  const [showScan, setShowScan] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [scanItem, setScanItem] = useState<any>(undefined);
  const [defaultDiscount, setDefaultDiscount] = useState(0);

  const prevStoreId = usePrevious(store.id);
  const cart = getFactoryCart(tempCart, factory?.factoryId);

  useEffect(() => {
    if (!store?.id && !prevStoreId) return;
    const {
      settings: { defaultDiscount: defaultDiscountId },
    } = store;
    if (defaultDiscountId) {
      onSelectDiscount(defaultDiscountId);
      setDefaultDiscount(defaultDiscountId);
    } else {
      setDefaultDiscount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.id]);

  if (!store) return null;

  const onSelectItem = (item) => {
    if (!item || !item.id) return;
    addProduct({
      ...item,
      price: item.retailPrice,
      settings: {
        disableDiscount: !!item?.settings?.disableDiscount,
        isRefund: false,
        isNew: true,
      },
    });
  };
  const onAddSelection = async () => {
    const checked = selections.reduce((acc, s, i) => (s && cart.products.length > i ? [...acc, i] : acc), []);
    await updateProducts(checked, {}, { isRefund: false, isNew: false });
    setSelections([]);
    setFilter({ resetSelection: !filter.resetSelection });
  };
  const onRefundSelection = async () => {
    const checked = selections.reduce((acc, s, i) => (s && cart.products.length > i ? [...acc, i] : acc), []);
    await updateProducts(checked, {}, { isRefund: true, isNew: false });
    setSelections([]);
    setFilter({ resetSelection: !filter.resetSelection });
  };
  const onDeleteSelection = async () => {
    const checked = selections.reduce((acc, s, i) => (s && cart.products.length > i ? [...acc, i] : acc), []);
    await removeProducts(checked);
    setSelections([]);
    setFilter({ resetSelection: !filter.resetSelection });
  };
  const onChangeQuantity = (i, qty, hasDecreased) => {
    updateProduct(i, { qty }, { isNew: false }, hasDecreased);
  };
  const onSelectDiscount = (discountId) => {
    const selectedDiscount = (store.discounts || []).find((d) => d.id === discountId);
    if (selectedDiscount) setDiscount(selectedDiscount);
    else clearDiscount();
  };
  const onSelectRefundDiscount = async (discountId) => {
    const selectedDiscount = (store.discounts || []).find((d) => d.id === discountId);
    if (selectedDiscount) setDiscount(selectedDiscount, true);
    else clearDiscount(true);
  };
  const onScanMode = () => {
    setShowScan(true);
    setScanItem(undefined);
  };
  const onScanFetch = async (result, barcode) => {
    setBarcode(barcode);
    if (!result?.data) {
      setScanItem(null);
    } else {
      const item = deriveRawToItem(result.data);
      setScanItem(item);
      onSelectItem(item);
    }
    await sleep(2000);
    setScanItem(undefined);
  };
  const onCheckout = async () => {
    const syncedCart = await syncCart(cart, store);
    if (checkCartStock(syncedCart)) {
      history.push(PATH.CHECKOUT);
      return;
    }
    refreshCart(syncedCart);
  };

  return (
    <IonPageWrapper>
      <MainHeader title="SCREEN.PO.TITLE" currentState={currentState} hasBack />
      <PoSearchBar
        scanMode={showScan}
        onScanFetch={onScanFetch}
        onSelectItem={onSelectItem}
        onClickScan={onScanMode}
      />
      <IonContent>
        <PageWrapper padding="0">
          <TableView2
            className="table2"
            filter={filter}
            onSelection={setSelections}
            data={cart.products || []}
            tableConfig={PO_TABLE_CONFIG}
            TABLE={PO_TABLE(currentState.isWholesale, onChangeQuantity)}
          />
        </PageWrapper>
      </IonContent>
      <PoFooter
        isSelected={selections.findIndex((p) => p) >= 0}
        cart={cart}
        defaultDiscount={defaultDiscount}
        refundDiscountId={cart.pricing.refundDiscount.id || 0}
        discounts={store.discounts}
        onAdd={onAddSelection}
        onDelete={onDeleteSelection}
        onRefund={onRefundSelection}
        onSelectDiscount={onSelectDiscount}
        onSelectRefundDiscount={onSelectRefundDiscount}
        onCheckout={onCheckout}
      />
      <ScanBackdrop item={scanItem} barcode={barcode} show={showScan} onClose={() => setShowScan(false)} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  store: state.store.store,
  cart: state.localStorage.cart,
});

const mapDispatchToProps = {
  addProduct: cartActions.addProduct,
  removeProducts: cartActions.removeProducts,
  updateProduct: cartActions.updateProduct,
  updateProducts: cartActions.updateProducts,
  refreshCart: cartActions.refreshCart,
  setDiscount: cartActions.setDiscount,
  clearDiscount: cartActions.clearDiscount,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(PoScreen));
