import React from 'react';
import { StoreItemWrapper } from './styles';

interface StoreItemProps {
  store: any;
  onSelect: Function;
}

const StoreItem: React.FC<StoreItemProps> = ({ store, onSelect }) => {
  if (!store) return null;
  return (
    <StoreItemWrapper onClick={onSelect}>
      <div className="store-name">{store.name}</div>
      <div className="address">{store.address}</div>
    </StoreItemWrapper>
  );
};

export default StoreItem;
