import React from 'react';
import { useDispatch } from 'react-redux';

import { initActions } from '$business/redux/init';
import { LoginScreen as GLoginScreen } from '$gbox/screens';

interface LoginScreenProps {
  [x: string]: any;
}

const LoginScreen: React.FC<LoginScreenProps> = ({ ...rest }) => {
  const dispatch = useDispatch();
  return (
    <>
      <GLoginScreen {...rest} onLogin={(values) => dispatch(initActions.login(values))} />
    </>
  );
};

export default LoginScreen;
