import { COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import intl from '$intl';

export const CHECKOUT_TABLE_CONFIG: TableModel = {
  dataSource: defaultDataSource,
  styles: {
    ...defaultStyles,
    responsive: true,
    color: COLORS.MEDIUM,
    fontSize: '1.05em',
    minWidth: 800,
    maxWidth: 1200,
    rowHeight: 20,
    cellPadding: ` 4px 4px`,
    headerHeight: 56,
    isStripped: false,
    borderColor: 'var(--ion-border-color)',
    disableSort: true,
    setRowClass: (row) => (row.settings?.isNew ? 'isNew' : ''),
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const CHECKOUT_TABLE = (isWholesale): Array<CellModel> => [
  {
    label: 'SCREEN.PO.COLS.TYPE',
    value: 'name',
    className: 'main',
    component: (row) => (
      <div className={`tag ${row.settings?.isRefund ? 'tertiary filled' : ''}`}>
        {intl('SCREEN.PO.' + (row.settings?.isRefund ? 'REFUND' : 'PURCHASE'))}
      </div>
    ),
    alwaysShow: true,
    width: 140,
  },
  {
    label: 'SCREEN.PO.COLS.SKU',
    value: 'sku',
    className: 'desktop',
    alwaysShow: true,
    width: 160,
  },
  {
    label: 'SCREEN.PO.COLS.ITEM_NAME',
    value: 'name',
    alwaysShow: true,
    width: 240,
  },
  {
    label: 'SCREEN.PO.COLS.CATEGORY',
    value: 'category',
    alwaysShow: true,
    width: 240,
  },
  {
    label: 'SCREEN.PO.COLS.UPC',
    value: 'barcode',
    alwaysShow: true,
    width: 240,
  },
  {
    label: 'SCREEN.PO.COLS.RETAIL_PRICE',
    value: 'retailPrice',
    alwaysShow: true,
    align: 'center',
    isHidden: isWholesale,
    className: 'desktop',
    component: (row) => <>{intl('PRICE', { amount: row.price.retailPrice || row.price.original })}</>,
    width: 180,
  },
  {
    label: 'SCREEN.PO.COLS.WHOLESALE_PRICE',
    value: 'wholesale',
    alwaysShow: true,
    align: 'center',
    isHidden: !isWholesale,
    component: (row) => <>{intl('PRICE', { amount: row.price.wholesalePrice })}</>,
    width: 180,
  },
  {
    label: 'SCREEN.PO.COLS.QTY',
    value: 'qty',
    align: 'center',
    component: (row) => <div className={`${row.error && 'error'}`}>{row.qty}</div>,
    width: 180,
  },
];
