import React from 'react';
import intl from '$intl';
import CartModel from '$fbusiness/models/cart';
import { Col, Row } from '$gstyles';
import { IonFooterWrapper } from './styles';
import { Button } from '$gcomponents/primitives';
import { Flex } from '$gstyles/wrapper';
import { currency, percentage } from '$gbusiness/helpers/util';
import { AddShoppingCart, Delete, RemoveCircle } from '@mui/icons-material';
import DiscountModel from '$fbusiness/models/discount';
import { FormControl, InputLabel, Select } from '@mui/material';
import { generateSelectOptions } from '$gcomponents/utils/input';
import { getCartPrices } from './utils';

interface PoFooterProps {
  onSelectDiscount: Function;
  onSelectRefundDiscount: Function;
  onCheckout: Function;
  isSelected?: boolean;
  onDelete: Function;
  onAdd: Function;
  onRefund: Function;
  defaultDiscount: number;
  refundDiscountId: number;
  discounts?: Array<DiscountModel>;
  cart: CartModel;
}

const PoFooter: React.FC<PoFooterProps> = ({
  cart,
  isSelected = false,
  onSelectDiscount,
  onSelectRefundDiscount,
  onAdd,
  onDelete,
  onRefund,
  refundDiscountId = 0,
  defaultDiscount = 0,
  discounts,
  onCheckout,
}) => {
  const discountId = cart.pricing.discount?.id || 0;
  const discountOptions = (discounts || []).map((d) => ({
    labelText: d.name,
    value: d.id,
  }));

  const { pricing } = cart;
  const hasRefund = (pricing.refund?.qty || 0) > 0;

  const price = getCartPrices(cart);

  return isSelected ? (
    <IonFooterWrapper>
      <Row>
        <Col gridSize={7} className="left">
          <Flex>
            <Button className="dark filled" endIcon={<Delete />} onClick={() => onDelete()}>
              {intl('BUTTON.DELETE')}
            </Button>
            <Button className="tertiary filled" endIcon={<RemoveCircle />} onClick={() => onRefund()}>
              {intl('SCREEN.PO.BUTTON.REFUND')}
            </Button>
          </Flex>
        </Col>
        <Col gridSize={5} className="right">
          <Button endIcon={<AddShoppingCart />} color="secondary" onClick={() => onAdd()}>
            {intl('SCREEN.PO.BUTTON.ADD_CART')}
          </Button>
        </Col>
      </Row>
    </IonFooterWrapper>
  ) : (
    <IonFooterWrapper>
      <Row>
        {(discounts || []).length > 0 && (
          <Col gridSize={4} className="discount-wrapper">
            <FormControl
              className="discount-form"
              fullWidth
              variant="outlined"
              size="small"
              disabled={defaultDiscount > 0}>
              <InputLabel id="discount-input">{intl('SCREEN.PO.DISCOUNT')}</InputLabel>
              <Select
                label={intl('SCREEN.PO.DISCOUNT')}
                labelId="discount-input"
                value={discountId}
                onChange={(e) => onSelectDiscount(e.target.value)}>
                {generateSelectOptions([...discountOptions, { labelText: 'None', value: '0' }], true)}
              </Select>
            </FormControl>
            {hasRefund && (
              <FormControl
                className="refund-discount-form"
                fullWidth
                variant="outlined"
                size="small"
                disabled={defaultDiscount > 0}>
                <InputLabel id="refund-input">{intl('SCREEN.PO.REFUND_DISCOUNT')}</InputLabel>
                <Select
                  label={intl('SCREEN.PO.REFUND_DISCOUNT')}
                  labelId="refund-input"
                  value={defaultDiscount > 0 ? discountId : refundDiscountId}
                  onChange={(e) => onSelectRefundDiscount(e.target.value)}>
                  {generateSelectOptions([...discountOptions, { labelText: 'None', value: '0' }], true)}
                </Select>
              </FormControl>
            )}
          </Col>
        )}
        <Col gridSize={5} className="total-wrapper">
          <Flex justifyContent="space-around">
            <Flex className="breakdown">
              <div className="outer">
                {discountId > 0 && (
                  <div className="inner">
                    <span className="label">{intl('SCREEN.PO.LIST_PRICE')}: </span>
                    <span className="list-price price">{currency(price.listPrice)}</span>
                  </div>
                )}
                <div className="inner">
                  <span className="label">{intl('SCREEN.PO.PRICE')}: </span>
                  <span className="discount-price price">{currency(price.price)}</span>
                </div>
                {hasRefund && (
                  <div>
                    <span className="tertiary filled inner">
                      <span className="label">{intl('SCREEN.PO.REFUND')}: </span>
                      <span className="refund-price price">{currency(price.refund)}</span>
                    </span>
                  </div>
                )}
              </div>
            </Flex>
            <Flex className="total-section">
              <div className="outer">
                <div className="inner">
                  <span className="label">{intl('SCREEN.PO.SUBTOTAL')}: </span>
                  <span className="total price">{currency(price.subtotal)}</span>
                </div>
                <div className="inner">
                  <span className="label">{intl('SCREEN.PO.SAVED')}: </span>
                  <span className="saved">
                    <span>{currency(price.discount)}</span>
                    <span>{price.savedRate > 0 && ` (${percentage(price.savedRate)})`}</span>
                  </span>
                </div>
              </div>
            </Flex>
          </Flex>
        </Col>
        <Col gridSize={3} Auto className="checkout-button-wrapper">
          <Button disabled={cart.hasError} fullWidth color="secondary" onClick={() => onCheckout()}>
            {intl('SCREEN.PO.BUTTON.CHECKOUT')}
          </Button>
        </Col>
      </Row>
    </IonFooterWrapper>
  );
};

export default PoFooter;
