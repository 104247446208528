import React from 'react';
import intl from '$intl';
import ItemModel from '$fbusiness/models/item';
import { Backdrop } from '@mui/material';

interface ScanBackdropProps {
  show: boolean;
  onClose: Function;
  barcode?: string;
  item?: ItemModel | null;
}

const ScanBackdrop: React.FC<ScanBackdropProps> = ({ show, barcode, item, onClose }) => {
  const msg = item ? `${barcode} Added` : item === null ? `${barcode} Not Found` : '';
  return (
    <Backdrop open={show} onClick={() => onClose()}>
      <div>
        <div>{intl('SCREEN.PO.SCANNING')}</div>
        <div className="message">{msg}</div>
      </div>
    </Backdrop>
  );
};

export default ScanBackdrop;
