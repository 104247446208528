import styled from 'styled-components';
import { IonFooter, IonHeader, IonPage } from '@ionic/react';
import { FONT, SPACE, WEIGHT } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .table2 {
    padding-top: 0;

    span.value {
      display: inline-block;
    }

    .MuiTableRow-root.isNew {
      background-color: #b3cee2;
    }
  }
  ion-backdrop,
  .MuiBackdrop-root {
    opacity: 0.8;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 11;
    display: flex;
    align-items: center;
    font-size: 60px;
    color: #fff;

    .message {
      font-size: 36px;
      height: 60px;
    }
  }
  ion-backdrop.backdrop-hide {
    z-index: -1;
  }
  .counter-input.error {
    input {
      background-color: #ffcccc;
    }
  }
`;

export const IonHeaderWrapper = styled(IonHeader)`
  padding: 0 8px;
  .scan-barcode {
    text-align: right;
  }
  .barcode-button {
    padding-top: 3px;
    padding-bottom: 3px;
    width: 90%;
  }
`;

export const IonFooterWrapper = styled(IonFooter)`
  padding: ${SPACE.MEDIUM};
  min-height: 44px;
  background-color: var(--ion-color-medium);

  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  button {
    margin-left: ${SPACE.MEDIUM};
    margin-right: ${SPACE.MEDIUM};
  }

  .discount-wrapper {
    display: flex;
    align-items: center;
  }

  .discount-form {
    flex: 4;
  }
  .refund-discount-form {
    flex: 3;
  }

  .price {
    font-weight: ${WEIGHT.SEMI_BOLD};
    font-size: ${FONT.MEDIUM};
  }

  .total-wrapper {
    .breakdown.refund .outer {
      background-color: var(--ion-color-tertiary);
    }
    .outer {
      padding: 2px;
    }
    .label {
      min-width: 60px;
      display: inline-block;
    }
    .inner {
      padding: 2px 6px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .breakdown {
      font-size: 0.9em;
      .list-price {
        text-decoration: line-through;
      }
      .discount-price {
        color: var(--ion-color-primary);
      }
    }
    .total-section {
      padding: 4px 0;
      font-size: 0.9em;
      .total {
        color: var(--ion-color-secondary);
        font-size: ${FONT.LARGE};
        margin-left: ${SPACE.MEDIUM};
      }
    }
  }
  .checkout-button-wrapper {
    display: flex;
    align-items: center;
  }

  .checkout {
    justify-content: space-around;
    .summary-wrapper {
      padding: 4px;
      min-width: 250px;
      font-size: 0.9em;
      .list-item {
        padding: 2px 8px;
        .value {
          margin-left: 8px;
        }
      }
    }
    .total-wrapper {
      min-width: 220px;
      .breakdown {
        padding: 4px;
      }
      &.refund {
        min-width: 200px;
      }
      &.total {
        min-width: 180px;
      }
    }
    .checkout-button-wrapper {
      min-width: 154px;
    }
    .price {
      font-size: inherit;
    }
    .discount-price {
      color: var(--ion-color-primary);
      font-size: ${FONT.MEDIUM};
    }
  }
`;
