import { ROUTES as GROUTES } from '$gbusiness/enums';
import * as FROUTES from '$fbusiness/enums/routes';
import RouteModel from '$gbusiness/models/route';
import PATH from './paths';

import { Restaurant, HomeOutlined } from '@mui/icons-material';
import {
  MenuScreen,
  CheckoutScreen,
  ThankyouScreen,
  HomeScreen,
  AboutUsScreen,
  PoScreen,
  LoginScreen,
} from '$product/screens';

export { PATH };

// IMPORTS FROM GBOX

export const HOME_ROUTE: RouteModel = {
  path: PATH.HOME,
  icon: HomeOutlined,
  component: HomeScreen,
};

export const LOGIN_ROUTE: RouteModel = {
  path: PATH.LOGIN,
  component: LoginScreen,
  exact: true,
};

export const PO_ROUTE: RouteModel = {
  path: PATH.PO,
  component: PoScreen,
};

export const ABOUT_US_ROUTE: RouteModel = {
  path: PATH.ABOUT_US,
  component: AboutUsScreen,
};

export const MENU_ROUTE: RouteModel = {
  // title: 'MENU.MENU',
  path: PATH.MENU,
  param: ':categoryId',
  defaultId: '0',
  icon: Restaurant,
  component: MenuScreen,
};

export const CHECKOUT_ROUTE: RouteModel = {
  path: PATH.CHECKOUT,
  component: CheckoutScreen,
};

export const THANKYOU_ROUTE: RouteModel = {
  path: PATH.THANKYOU,
  param: ':orderId?/:refundId?',
  component: ThankyouScreen,
  exact: true,
};

export const PUBLIC_LIST2: Array<RouteModel> = [LOGIN_ROUTE];
export const PRIVATE_LIST: Array<RouteModel> = [
  HOME_ROUTE,
  PO_ROUTE,
  MENU_ROUTE,
  ABOUT_US_ROUTE,
  CHECKOUT_ROUTE,
  THANKYOU_ROUTE,
  FROUTES.DAILY_REPORT_ROUTE,
  // FROUTES.DAILY_REPORTS_ROUTE,
  FROUTES.TOS_ROUTE,
  FROUTES.ACCOUNT_ROUTE,
  FROUTES.HISTORY_ROUTE,
  FROUTES.INVOICES_ROUTE,
  // FROUTES.RECEIVING_ROUTE,
  FROUTES.AR_REPORT_ROUTE,
  FROUTES.CARTS_ROUTE,
  // FROUTES.CREDITS_ROUTE,
  FROUTES.PROFILE_ROUTE,
  FROUTES.MY_FILES_ROUTES,
  FROUTES.MY_VENDORS_ROUTES,
  FROUTES.USERS_ROUTE,
  FROUTES.USER_ROUTE,
  FROUTES.SETTINGS_ROUTE,
];

export const LIST: Array<RouteModel> = [...PUBLIC_LIST2, ...PRIVATE_LIST];

export const ACCOUNT_LIST = [
  FROUTES.HISTORY_ROUTE,
  // FROUTES.RECEIVING_ROUTE,
  FROUTES.INVOICES_ROUTE,
  // FROUTES.CREDITS_ROUTE,
  FROUTES.DAILY_REPORT_ROUTE,
  // FROUTES.AR_REPORT_ROUTE,
  FROUTES.MY_FILES_ROUTES,
  FROUTES.PROFILE_ROUTE,
];
export const ORDER_LIST_PATHS = [PATH.HOME, PATH.MENU, PATH.CHECKOUT, PATH.THANKYOU, PATH.ABOUT_US];

export const MENU_LIST: Array<RouteModel> = [FROUTES.ACCOUNT_ROUTE];
export const PUBLIC_LIST = [...GROUTES.PUBLIC_LIST, ...PUBLIC_LIST2];

export const DEFAULT_PUBLIC_ROUTE = LOGIN_ROUTE;
export const DEFAULT_PUBLIC_PATH = DEFAULT_PUBLIC_ROUTE.path;

export const defaultRoute = HOME_ROUTE;
export const DEFAULT_APP_PATH = defaultRoute.path;
// export const DEFAULT_APP_ROUTE = defaultRoute.path + '/' + defaultRoute.defaultId;
