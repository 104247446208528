import React from 'react';
import intl from '$intl';
import CartModel from '$fbusiness/models/cart';
import { Col, Row } from '$gstyles';
import { IonFooterWrapper } from '../poScreen/styles';
import { Button } from '$gcomponents/primitives';
import { currency, formatAddress, percentage } from '$gbusiness/helpers/util';
import { InfoItem } from '$gcomponents/reusables';
import StoreModel from '$fbusiness/models/store';
import UserModel from '$gbusiness/models/user';

interface CheckoutFooterProps {
  onSubmitPo: Function;
  cart: CartModel;
  store: StoreModel;
  user: UserModel;
}

const CheckoutFooter: React.FC<CheckoutFooterProps> = ({ cart, store, user, onSubmitPo }) => {
  const { pricing } = cart;
  const { po, refund, final, discountRate } = pricing;

  const hasRefund = (refund?.qty || 0) > 0;
  const hasPo = (po?.qty || 0) > 0;
  const { formatted } = store;
  const { firstName, lastName } = user;
  const fullName = `${firstName} ${lastName}`;

  return (
    <IonFooterWrapper>
      <Row className="checkout">
        <Col gridSize={2} className="summary-wrapper">
          <div className="outer">
            <InfoItem label="SCREEN.CHECKOUT.DISCOUNT_RATE" value={percentage(discountRate)} />
            <InfoItem label="SCREEN.CHECKOUT.POC" value={fullName} />
            <InfoItem label="SCREEN.CHECKOUT.STORE_INFO" value={formatAddress(formatted)} />
          </div>
        </Col>

        {hasRefund && (
          <Col gridSize={2} className="total-wrapper refund">
            <div className="breakdown refund">
              <div className="outer">
                <div className="inner">
                  <span className="label">{intl('SCREEN.CHECKOUT.REFUND_SUBTOTAL')}: </span>
                  <span className="price">{currency(refund.taxable)}</span>
                </div>
                {refund.totalDiscount > 0 && (
                  <div className="inner">
                    <span className="label">{intl('SCREEN.PO.REFUND_DISCOUNT')}: </span>
                    <span className="price">({currency(refund.totalDiscount || 0)})</span>
                  </div>
                )}
                <div className="inner">
                  <span className="label">{intl('SCREEN.CHECKOUT.TAX')}: </span>
                  <span className="price">{currency(refund.tax)}</span>
                </div>
                <div>
                  <span className="inner">
                    <span className="label">{intl('SCREEN.CHECKOUT.REFUND')}: </span>
                    <span className="price">{currency(refund.total)}</span>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        )}
        {hasPo && (
          <Col gridSize={2} className="total-wrapper">
            <div className="breakdown">
              <div className="outer">
                <div className="inner">
                  <span className="label">{intl('SCREEN.CHECKOUT.PURCHASE_SUBTOTAL')}: </span>
                  <span className="price">{currency(po.taxable)}</span>
                </div>
                {po.totalDiscount > 0 && (
                  <div className="inner">
                    <span className="label">{intl('SCREEN.PO.DISCOUNT')}: </span>
                    <span className="price o60">({currency(po.totalDiscount || 0)})</span>
                  </div>
                )}
                <div className="inner">
                  <span className="label">{intl('SCREEN.CHECKOUT.TAX')}: </span>
                  <span className="price">{currency(po.tax)}</span>
                </div>
                <div>
                  <span className="inner">
                    <span className="label">{intl('SCREEN.CHECKOUT.PURCHASE_TOTAL')}: </span>
                    <span className="discount-price price">{currency(po.total)}</span>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        )}
        <Col gridSize={2} className="total-wrapper total">
          <div className="total-section">
            <div className="outer">
              <div className="inner">
                <span className="label">{intl('SCREEN.PO.TOTAL')}: </span>
                <span className="total price">{currency(final?.total)}</span>
              </div>
              <div className="inner">
                <span className="label">{intl('SCREEN.PO.SAVED')}: </span>
                <span className="saved">
                  <span>{currency(final.totalDiscount)}</span>
                  <span>{po.savedRate > 0 && ` (${percentage(po.savedRate)})`}</span>
                </span>
              </div>
            </div>
          </div>
        </Col>

        <Col gridSize={1} Auto className="checkout-button-wrapper">
          <Button
            disabled={cart.hasError}
            className="submit-button"
            fullWidth
            color="secondary"
            onClick={() => onSubmitPo()}>
            {intl('SCREEN.CHECKOUT.BUTTON_SUBMIT')}
          </Button>
        </Col>
      </Row>
    </IonFooterWrapper>
  );
};

export default CheckoutFooter;
