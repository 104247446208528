import React from 'react';

import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { currency } from '$gbusiness/helpers/util';
import { deriveRawToAvailableCredit } from '$fbusiness/models/credit';
import { format } from '$gbusiness/helpers/date';
import { Flex } from '$gstyles/wrapper';
import { Edit } from '@mui/icons-material';

export const CREDIT_TABLE_CONFIG = (storeId): TableModel => ({
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.store.availableCredits + '/' + storeId,
    deriveToModel: deriveRawToAvailableCredit,
    method: 'GET',
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 800,
    maxWidth: 1200,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 44,
    setRowClass: (row) => (row.amount < 'DISCOUNT' ? 'negative-row' : ''),
  },
});

export const CREDIT_TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.CREDIT.COLS.NO',
    value: 'id',
    component: (row) => <>{row.display}</>,
    width: 140,
  },
  {
    label: 'SCREEN.CREDIT.COLS.DATE',
    value: 'creditDate',
    component: (row) => <>{format(row.createdAt, DATE_FORMATS.DATETIME) || ''}</>,
    width: 200,
  },
  {
    label: 'SCREEN.CREDIT.COLS.AMOUNT',
    value: 'amount',
    align: 'center',
    component: (row) => <span>{currency(row.amount)}</span>,
    alwaysShow: true,
    width: 180,
  },
  {
    label: 'SCREEN.CREDIT.COLS.BALANCE',
    value: 'balance',
    align: 'center',
    component: (row) => (
      <span className={`price semi-bold outlined ${row.amount <= 0 ? 'danger' : 'success'}`}>
        {currency(row.balance)}
      </span>
    ),
    alwaysShow: true,
    width: 220,
  },
  {
    label: 'SCREEN.CREDIT.COLS.MEMO',
    value: 'memo',
    alwaysShow: true,
    width: 400,
  },
];

export const creditTableEditable = (onEditPayment): Array<CellModel> => {
  return [
    ...CREDIT_TABLE,
    {
      label: '',
      value: '',
      component: (row, i) => (
        <div>
          <Flex justifyContent="flex-end">
            {!row.type && <Edit onClick={() => onEditPayment(row, i)} className="pointer small" />}
          </Flex>
        </div>
      ),
      width: 50,
    },
  ];
};
