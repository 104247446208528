import React from 'react';

import { MainButtonWrapper } from './styles';

interface MainButtonProps {
  text: string;
  onClick: (e) => void;
}

const MainButton: React.FC<MainButtonProps> = ({ text, onClick }) => {
  return (
    <MainButtonWrapper gridSize={6}>
      <div className="button" onClick={onClick}>
        {text}
      </div>
    </MainButtonWrapper>
  );
};

export default MainButton;
