import React from 'react';

import { COLORS } from '$gbusiness/enums';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import CellModel from '$gbusiness/models/cell';
import { currency } from '$gbusiness/helpers/util';
import { deriveRawToOrderDetails } from '$fbusiness/models/orderDetails';

export const ORDER_DETAILS_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    deriveToModel: deriveRawToOrderDetails,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 500,
    maxWidth: 900,
    cellPadding: ` 1px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 22,
    headerHeight: 50,
  },
  blankText: (row, i) => <div>{!row.itemdId && row.subtotal ? '' : row.itemName}</div>,
};

export const ORDER_DETAILS_TABLE = ({
  isInvoice,
  isFlagged,
  isRegenerated,
  hidePrice,
  showVendor = false,
}): Array<CellModel> => [
  {
    label: 'SCREEN.ORDER.COLS.SKU',
    value: 'sku',
    alwaysShow: true,
    component: (row) => <>{!row.itemId && !row.subtotal ? '' : row.sku}</>,
    width: 150,
  },
  {
    label: 'SCREEN.ORDER.COLS.ITEM',
    value: 'itemName',
    component: (row) =>
      !row.itemId && !row.subtotal ? (
        <></>
      ) : (
        <div>
          <div>{row.itemName}</div>
          {row.modifiers?.length > 0 && (
            <div className="mod">({row.modifiers.map((i) => i.name).join(', ')})</div>
          )}
          {row.note && <div className="mod">{`"${row.note}"`}</div>}
        </div>
      ),
    className: 'main',
    alwaysShow: true,
    width: 300,
  },
  {
    isHidden: !showVendor,
    label: 'SCREEN.ORDER.COLS.VENDOR',
    value: 'vendor.code',
    component: (row) => {
      return <>{!row.itemId && !row.subtotal ? '' : row.vendor?.code || ''}</>;
    },
    alwaysShow: true,
    width: 150,
  },
  {
    isHidden: isInvoice,
    label: 'SCREEN.ORDER.COLS.QTY',
    value: 'qtyOrdered',
    align: 'center',
    component: (row) => {
      return <>{!row.itemId && !row.subtotal ? '' : row.qtyOrdered || row.qty}</>;
    },
    className: 'desktop',
    width: 120,
  },
  {
    isHidden: !isRegenerated,
    label: 'SCREEN.INVOICES.COLS.QTY_STATUS_TOTAL',
    value: '',
    component: (row) => <>{!row.itemId && !row.subtotal ? '' : `${row.totalReceived}/${row.totalSent}`}</>,
    align: 'center',
    width: 120,
  },
  {
    isHidden: !isFlagged,
    label: 'SCREEN.INVOICES.COLS.QTY_STATUS',
    value: '',
    component: (row) => (
      <div
        className={row.qtyReceived !== row.qtySent ? 'error' : ''}>{`${row.qtyReceived}/${row.qtySent}`}</div>
    ),
    align: 'center',
    width: 120,
  },
  {
    isHidden: isFlagged || !isInvoice,
    label: 'SCREEN.ORDER.COLS.QTY_SENT',
    value: '',
    component: (row) => <>{!row.itemId && !row.subtotal ? '' : row.qtySent}</>,
    align: 'center',
    width: 120,
  },
  {
    isHidden: !isRegenerated,
    label: 'SCREEN.INVOICES.COLS.QTY_ADJUSTED',
    value: 'qty',
    align: 'center',
    width: 120,
  },

  // {
  //   isHidden: !isInvoice,
  //   label: 'SCREEN.ORDER.COLS.QTY_SENT',
  //   value: 'totalSent',
  //   component: row => (hasProblem ? <>{`${row.qtySent}/${row.totalSent}`}</> : <>{row.totalSent}</>),
  //   align: 'center',
  //   className: 'desktop',
  //   width: 120,
  // },
  // {
  //   isHidden: !isFlagged,
  //   label: 'SCREEN.ORDER.COLS.QTY_RECEIVED',
  //   value: 'totalReceived',
  //   component: row => <>{`${row.qtyReceived}/${row.totalReceived}`}</>,
  //   align: 'center',
  //   className: 'desktop',
  //   width: 120,
  // },
  // ...(hasProblem
  //   ? [
  //       {
  //         label: 'SCREEN.SHIPPING_MODAL.COLS.QTY',
  //         value: 'qty',
  //         align: 'center',
  //         width: 200,
  //       },
  //     ]
  //   : []),
  {
    isHidden: hidePrice,
    label: 'SCREEN.ORDER.COLS.PRICE',
    align: 'right',
    value: 'total',
    className: 'invoice-print',
    alwaysShow: true,
    component: (row) => <>{!row.itemId && !row.subtotal ? '' : currency(row.unitPrice)}</>,
    width: 150,
  },
  {
    isHidden: hidePrice,
    label: 'SCREEN.ORDER.COLS.TOTAL',
    value: 'total',
    align: 'right',
    alwaysShow: true,
    className: 'invoice-print',
    component: (row) => (
      <>{!row.itemId && !row.subtotal ? '' : currency(isInvoice ? row.subtotal : row.unitPrice * row.qty)}</>
    ),
    width: 150,
    minWidth: 110,
    maxWidth: 110,
  },
];
